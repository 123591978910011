import moment from "moment";
import { useEffect, useState } from "react";
import EnterpriseService from "../../../common/services/enterprise-service";
import classNames from "classnames";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventDetailCard from "./EventDetailCard";
import { Link } from "react-router-dom";
import TodayEvents from "./TodayEvents";
import LazyImage from "../common/LazyImage";

export function EventsCalendar({ container, company_id }) {
  const es = new EnterpriseService();
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("calendar");
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState(null);
  const [mode, setMode] = useState(null);

  // get weekdays from moment but the first must be Monday
  const weekDays = moment.weekdays();
  weekDays.push(weekDays.shift());    

  const getEvents = async () => {
    setLoading(true);
    setEvents([]);
    let dateFrom = moment(currentDate);
    let dateTo = moment(currentDate);

    dateFrom.startOf("month");
    dateTo.endOf("month").endOf("day");

    if (dateFrom.isBefore(moment().startOf("day"))) {
      dateFrom = moment().startOf("day");
    }

    let result = null;

    if (company_id) {
      result = await es.getCompanyEventsCalendar(company_id, {
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
        game_id: game,
        mode: mode,
      });
    } else {
      result = await es.getPublicCompanyEvents({
        date_from: dateFrom.format("YYYY-MM-DD"),
        date_to: dateTo.format("YYYY-MM-DD"),
        game_id: game,
        mode: mode,
      });
    }

    if (result) {
      setEvents(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, [currentDate, game, mode]);

  const getDaysToShow = () => {
    const date = moment(currentDate);
    // creates an array of dates containing the current month but also the previous and the next month days to fill all weeks
    const dates = [];
    const start = date.clone().startOf("month").startOf("isoWeek");
    const end = date.clone().endOf("month").endOf("isoWeek");

    while (start.isBefore(end)) {
      dates.push(start.clone());
      start.add(1, "day");
    }

    return dates;
  };

  const getEventsForDay = (day) => {
    return events.filter(
      (event) =>
        moment(event.start_date).format("YYYY-MM-DD") ===
        day.format("YYYY-MM-DD")
    );
  };

  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <TodayEvents
          container={container}
          company_id={company_id}
        ></TodayEvents>

        <Card className="mb-3 bg-light mx-0">
          <CardBody>
            <FormGroup row>
              <Col>
                <Label>{container.localeManager.strings.game}</Label>
                <Input type="select" onChange={(e) => setGame(e.target.value)}>
                  <option></option>
                  <option value="1">Euro Truck Simulator 2</option>
                  <option value="2">American Truck Simulator</option>
                </Input>
              </Col>
              <Col>
                <Label>{container.localeManager.strings.platform}</Label>
                <Input type="select" onChange={(e) => setMode(e.target.value)}>
                  <option></option>
                  <option value="truckersmp">TruckersMP</option>
                  <option value="convoymode">
                    {container.localeManager.strings.convoy}
                  </option>
                </Input>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
        <div className="d-flex justify-content-between mb-3">
          <div className="fs-2">{moment(currentDate).format("MMMM YYYY")}</div>
          <div className="d-flex">
            <div className="me-3">
              <Button
                disabled={moment()
                  .startOf("month")
                  .isSame(moment(currentDate).startOf("month"))}
                onClick={() =>
                  setCurrentDate(
                    moment(currentDate).subtract(1, "month").toDate()
                  )
                }
                color="primary"
                className="me-3"
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  className="mx-0"
                ></FontAwesomeIcon>
              </Button>
              <Button
                onClick={() =>
                  setCurrentDate(moment(currentDate).add(1, "month").toDate())
                }
                color="primary"
              >
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="mx-0"
                ></FontAwesomeIcon>
              </Button>
            </div>
            <ButtonGroup>
              <Button
                onClick={() => setCurrentView("calendar")}
                color="primary"
              >
                <FontAwesomeIcon
                  icon="calendar"
                  className="mx-0"
                ></FontAwesomeIcon>
              </Button>
              <Button onClick={() => setCurrentView("grid")} color="primary">
                <FontAwesomeIcon icon="grip" className="mx-0"></FontAwesomeIcon>
              </Button>
              {/**
              <Button onClick={() => setCurrentView("table")} color="primary">
                <FontAwesomeIcon
                  icon="table"
                  className="mx-0"
                ></FontAwesomeIcon>
              </Button>
              */}
            </ButtonGroup>
          </div>
        </div>
        {currentView == "calendar" && (
          <>
            <div className="grid" style={{ "--bs-columns": 7, "--bs-gap": 0 }}>
              {weekDays.map((day) => (
                <div
                  className="d-none d-lg-block border p-3 text-center g-col-1"
                  key={day}
                >
                  {day}
                </div>
              ))}
            </div>
            <div
              className="grid g-0"
              style={{ "--bs-columns": 7, "--bs-gap": 0 }}
            >
              {getDaysToShow().map((day) => (
                <div
                  key={day.toString()}
                  className={classNames({
                    "g-col-lg-1": true,
                    "g-col-md-3": true,
                    "g-col-7": true,
                    border: true,
                    "p-3": true,
                    "min-h-50px": true,
                    "bg-light": day.month() !== currentDate.getMonth(),
                    "text-muted": day.month() !== currentDate.getMonth(),
                    "bg-success":
                      day.format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD"),
                    "d-none": getEventsForDay(day).length === 0,
                    "d-lg-block": true,
                  })}
                >
                  <div className="text-end text-gray-600">
                    {day.format("DD")}
                  </div>
                  {getEventsForDay(day).map((event) => (
                    <>
                      <div
                        className="d-flex bg-light p-2 mb-2 rounded"
                        key={event.id}
                        id={`event_${event.id}`}
                      >
                        <LazyImage
                          className="avatar h-20px min-w-20px rounded me-3"
                          src={event.company?.avatar_url}
                          placeholder="/assets/company-placeholder.png"
                          alt={event.company?.name}
                          title={event.company?.name}
                        ></LazyImage>
                        <Link to={`/events/${event.id}/${event.slug}`}>
                          {event.title}
                        </Link>
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
        {currentView == "grid" && (
          <div>
            <Row noGutters>
              {events.map((event) => (
                <Col sm={12} md={6} lg={4} key={event.id}>
                  <EventDetailCard
                    event={event}
                    container={container}
                  ></EventDetailCard>
                </Col>
              ))}
            </Row>
          </div>
        )}
        {/**
      <Calendar
        components={components}
        localizer={localizer}
        events={calendarEvents}
        views={["month", "week"]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        min={new Date()}
        onNavigate={setCurrentDate}
        onView={setCurrentView}
        showAllEvents={true}
        onSelectEvent={(event) => {
          navigate(`/events/${event.internalEvent.id}/${event.internalEvent.slug}`);
        }}
         
      />
       */}
      </LoadingOverlay>
    </div>
  );
}
