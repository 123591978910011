import { useNavigate, useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import Select from "react-select";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import Separator from "../ui/Separator";
import MDEditor from "@uiw/react-md-editor";
import ErrorBox from "../ui/ErrorBox";
import LoadingButton from "../ui/LoadingButton";
import { getZonedTimeFormatted } from "../../web/utils";
import { serialize } from "object-to-formdata";
import classNames from "classnames";
import CompanyEventSlotsManagement from "./CompanyEventSlotsManagement";
import Datetime from "react-datetime";
import truckyService from "../../common/services/trucky-service";
import NotEnoughPermissions from "./NotEnoughPermissions";

export default function EditCompanyEVent({ container }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const es = new EnterpriseService();
  const { globalContext } = useContext(EnterpriseContext);
  const [event, setData] = useState({});
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [servers, setServers] = useState([]);
  const [cover, setCover] = useState(null);
  const [route, setRoute] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const [discordChannels, setDiscordChannels] = useState([]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    truckyService.setDocumentTitle("Edit Company Event");

    if (id) {
      getData();
    }
    getLanguages();
  }, []);

  const getData = async () => {
    const result = await es.getCompanyEventForEdit(globalContext.company, id);
    if (result) {
      setData(result);
    }
  };

  const getLanguages = async () => {
    const result = await es.getLanguages();
    setLanguages(result);
  };

  const save = async () => {
    setLoading(true);
    setErrors([]);
    let response;

    const formData = serialize(event, {
      booleansAsIntegers: true,
    });

    if (cover != null) {
      formData.append("cover_upload", cover);
    }

    if (route != null) {
      formData.append("route_upload", route);
    }

    if (!event.id) {
      response = await es.createCompanyEvent(formData);
    } else {
      response = await es.updateCompanyEvent(event.id, formData);
    }

    if (response.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      if (!event.id) {
        navigate(`/company/events/manage/edit/${response.event.id}`);
      } else {
        setData(response.event);
      }
    } else {
      setErrors(response.message);
    }
    setLoading(false);
  };

  const getDedicatedServers = async () => {
    const result = await es.getCompanyServers(globalContext.company.id);
    setServers(result);
  };

  useEffect(() => {
    if (event.mode && event.mode == "convoymode") {
      getDedicatedServers();
    }
  }, [event.mode]);

  const syncTMPEvent = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.areYouSureToSyncThisEvent,
        {
          container: container,
        }
      )
    ) {
      setLoading(true);
      const result = await es.syncTMPEvent(event.id);
      if (result.success) {
        toast.success(container.localeManager.strings.dataUpdated);
        setData(result.event);
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (event.discord_sync) {
      getDiscordChannels();
    }
  }, [event?.discord_sync]);

  const getDiscordChannels = async () => {
    const result = await es.getCompanyDiscordServerChannels(
      globalContext.company.id
    );
    setDiscordChannels(result);
  };

  const publishToDiscord = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.areYouSureToPublishThisEventToDiscord,
        {
          container: container,
        }
      )
    ) {
      setLoading(true);
      const result = await es.publishCompanyEventToDiscord(event.id);
      if (result.success) {
        toast.success("Event published to Discord");
        setData(result.event);
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  if (!es.can(globalContext.member, "events.manage")) {
    return <NotEnoughPermissions container={container} />;
  }

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row noGutters>
          <VTCMenu active={"/company/events"} container={container}></VTCMenu>
          <Col>
            <Card>
              <CardBody>
                <Breadcrumbs
                  breadcrumbs={[
                    {
                      to: "/vtchub",
                      title: container.localeManager.strings.vtcHub,
                    },
                    {
                      to: "/company/events",
                      title: container.localeManager.strings.companyEvents,
                    },
                    {
                      title: container.localeManager.strings.details,
                    },
                  ]}
                ></Breadcrumbs>
                {event && event.message && (
                  <ErrorBox errors={[event.message]}></ErrorBox>
                )}
                {event && !event.message && (
                  <>
                    <h5 className="d-flex justify-content-between align-items-center mb-3">
                      {container.localeManager.strings.editCompanyEvent}
                      <div>
                        {event.external_id && event.external_url && (
                          <>
                            <LoadingButton
                              color="primary"
                              size="sm"
                              onClick={syncTMPEvent}
                              className="me-3"
                            >
                              {
                                container.localeManager.strings
                                  .syncDetailsFromTruckersMP
                              }
                            </LoadingButton>
                            <Button
                              color="secondary"
                              size="sm"
                              tag="a"
                              href={event.external_url}
                              target="_blank"
                              className="me-3"
                            >
                              {
                                container.localeManager.strings
                                  .truckersMPEventPage
                              }{" "}
                              <FontAwesomeIcon
                                icon="external-link-alt"
                                className="ms-2 me-0"
                              ></FontAwesomeIcon>
                            </Button>
                          </>
                        )}
                        {event.id &&
                          event.discord_sync &&
                          (event.discord_event_id == null ||
                            event.discord_message_id == null) && (
                            <LoadingButton
                              color="primary"
                              size="sm"
                              onClick={publishToDiscord}
                              className="me-3"
                            >
                              {container.localeManager.strings.publishToDiscord}
                            </LoadingButton>
                          )}
                        {event.id && (
                          <Button
                            color="primary"
                            size="sm"
                            tag="a"
                            href={event.public_url}
                            target="_blank"
                          >
                            {container.localeManager.strings.publicPage}{" "}
                            <FontAwesomeIcon
                              icon="external-link-alt"
                              className="ms-2 me-0"
                            ></FontAwesomeIcon>
                          </Button>
                        )}
                      </div>
                    </h5>
                    <Nav
                      className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
                      tabs
                    >
                      <NavItem>
                        <NavLink
                          className={classNames({
                            active: activeTab === "details",
                          })}
                          onClick={() => {
                            setActiveTab("details");
                          }}
                        >
                          {container.localeManager.strings.details}
                        </NavLink>
                      </NavItem>
                      {event && event.id != null && event.id > 0 && (
                        <NavItem>
                          <NavLink
                            className={classNames({
                              active: activeTab === "slots",
                            })}
                            onClick={() => {
                              setActiveTab("slots");
                            }}
                          >
                            {container.localeManager.strings.slots}
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <TabContent className="mt-3" activeTab={activeTab}>
                      <TabPane tabId="details">
                        {event.id && event.external_id && (
                          <FormGroup row>
                            <Col sm={2}>
                              <Label>
                                {
                                  container.localeManager.strings
                                    .truckersMPEventID
                                }
                              </Label>
                              <Input disabled value={event.external_id}></Input>
                            </Col>
                          </FormGroup>
                        )}
                        <FormGroup row>
                          <Col>
                            <Label>
                              {container.localeManager.strings.game} *
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setData({ ...event, game_id: e.target.value })
                              }
                              value={event.game_id}
                            >
                              <option></option>
                              <option value={1}>Euro Truck Simulator 2</option>
                              <option value={2}>
                                American Truck Simulator
                              </option>
                            </Input>
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.platform} *
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setData({ ...event, mode: e.target.value })
                              }
                              value={event.mode}
                            >
                              <option></option>
                              <option value="truckersmp">TruckersMP</option>
                              <option value="convoymode">
                                {container.localeManager.strings.convoy}
                              </option>
                            </Input>
                          </Col>
                          <Col>
                            <Label>
                              {
                                container.localeManager.strings
                                  .truckersMPServerOrNonTruckyServer
                              }
                            </Label>
                            <Input
                              onChange={(e) =>
                                setData({ ...event, server: e.target.value })
                              }
                              value={event.server}
                            ></Input>
                          </Col>
                          {servers.length > 0 && event.mode == "convoymode" && (
                            <Col>
                              <Label>
                                {
                                  container.localeManager.strings
                                    .dedicatedServers
                                }
                              </Label>
                              <Input
                                type="select"
                                onChange={(e) =>
                                  setData({
                                    ...event,
                                    dedicated_server_configuration_id:
                                      e.target.value,
                                  })
                                }
                                value={event.dedicated_server_configuration_id}
                              >
                                <option></option>
                                {servers.map((m) => {
                                  return (
                                    <option value={m.id}>
                                      {m.lobby_name} ({m.game.code})
                                    </option>
                                  );
                                })}
                              </Input>
                            </Col>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.title} *
                          </Label>
                          <Input
                            onChange={(e) =>
                              setData({ ...event, title: e.target.value })
                            }
                            value={event.title}
                          ></Input>
                        </FormGroup>
                        <Separator></Separator>
                        <FormGroup row>
                          <Col>
                            <Label>
                              {container.localeManager.strings.meetupDate} *
                            </Label>
                            <div>
                              <Datetime
                                value={moment(event.meetup_date).toDate()}
                                timeFormat="HH:mm"
                                utc={true}
                                field={false}
                                onChange={(e) =>
                                  setData({ ...event, meetup_date: e.toDate() })
                                }
                              />
                              {event.meetup_date && (
                                <FormText>
                                  {
                                    container.localeManager.strings
                                      .inYourLocalTimezone
                                  }
                                  : {getZonedTimeFormatted(event.meetup_date)}
                                </FormText>
                              )}
                            </div>
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.startDateUTC} *
                            </Label>
                            <div>
                              <Datetime
                                value={moment(event.start_date).toDate()}
                                timeFormat="HH:mm"
                                utc={true}
                                field={false}
                                onChange={(e) =>
                                  setData({ ...event, start_date: e.toDate() })
                                }
                              />
                              {event.start_date && (
                                <FormText>
                                  {
                                    container.localeManager.strings
                                      .inYourLocalTimezone
                                  }
                                  : {getZonedTimeFormatted(event.start_date)}
                                </FormText>
                              )}
                            </div>
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.startCity} *
                            </Label>
                            <Input
                              onChange={(e) =>
                                setData({
                                  ...event,
                                  start_city: e.target.value,
                                })
                              }
                              value={event.start_city}
                            ></Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col sm={4}>
                            <Label>
                              {container.localeManager.strings.startLocation} *
                            </Label>
                            <Input
                              onChange={(e) =>
                                setData({
                                  ...event,
                                  start_location: e.target.value,
                                })
                              }
                              value={event.start_location}
                            ></Input>
                          </Col>
                          <Col sm={4}>
                            <Label>
                              {container.localeManager.strings.eventType} *
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setData({
                                  ...event,
                                  event_type: e.target.value,
                                })
                              }
                              value={event.event_type}
                            >
                              <option value=""></option>
                              <option value="convoy">
                                {container.localeManager.strings.convoy}
                              </option>
                              <option value="meetup">
                                {container.localeManager.strings.meetup}
                              </option>
                              <option value="truck_show">
                                {container.localeManager.strings.truck_show}
                              </option>
                              <option value="truck_show_and_convoy">
                                {
                                  container.localeManager.strings
                                    .truck_show_and_convoy
                                }
                              </option>
                            </Input>
                          </Col>
                        </FormGroup>
                        {event.event_type != "meetup" &&
                          event.event_type != "truck_show" && (
                            <FormGroup row>
                              <Col sm={4}>
                                <Label>
                                  {container.localeManager.strings.endCity} *
                                </Label>
                                <Input
                                  onChange={(e) =>
                                    setData({
                                      ...event,
                                      end_city: e.target.value,
                                    })
                                  }
                                  value={event.end_city}
                                ></Input>
                              </Col>
                              <Col sm={4}>
                                <Label>
                                  {container.localeManager.strings.endLocation}{" "}
                                  *
                                </Label>
                                <Input
                                  onChange={(e) =>
                                    setData({
                                      ...event,
                                      end_location: e.target.value,
                                    })
                                  }
                                  value={event.end_location}
                                ></Input>
                              </Col>
                            </FormGroup>
                          )}
                        <FormGroup row>
                          <Col sm={4}>
                            <Label>
                              {container.localeManager.strings.language} *
                            </Label>
                            <Select
                              onChange={(val) =>
                                setData({
                                  ...event,
                                  language: val.name,
                                })
                              }
                              value={languages.find(
                                (m) => m.name == event.language
                              )}
                              options={languages}
                              styles={reactSelectCustomStyles}
                              getOptionValue={(m) => m.code}
                              getOptionLabel={(m) => m.name}
                            ></Select>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col>
                            <Label>
                              {container.localeManager.strings.cover}
                            </Label>
                            <Input
                              type="file"
                              onChange={(e) => setCover(e.target.files[0])}
                            ></Input>
                            {event.cover_url && (
                              <img
                                className="mt-3"
                                style={{ maxHeight: 300 }}
                                src={event.cover_url}
                              ></img>
                            )}
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.route}
                            </Label>
                            <Input
                              type="file"
                              onChange={(e) => setRoute(e.target.files[0])}
                            ></Input>
                            {event.route_url && (
                              <img
                                style={{ maxHeight: 300 }}
                                className="mt-3"
                                src={event.route_url}
                              ></img>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col sm={4}>
                            <Label>
                              {container.localeManager.strings.status}
                            </Label>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setData({ ...event, status: e.target.value })
                              }
                              value={event.status}
                            >
                              <option></option>
                              <option value="draft">
                                {container.localeManager.strings.draft}
                              </option>
                              <option value="published">
                                {container.localeManager.strings.published}
                              </option>
                            </Input>
                          </Col>
                          <Col className="d-flex align-items-end">
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                onChange={(e) => {
                                  setData({
                                    ...event,
                                    private: e.target.checked,
                                  });
                                }}
                                class="form-check-input"
                                type="checkbox"
                                checked={event.private}
                              />
                              <span class="form-check-label">
                                {
                                  container.localeManager.strings
                                    .privateCompanyEvent
                                }
                              </span>
                            </label>
                          </Col>
                          <Col className="d-flex align-items-end">
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                onChange={(e) => {
                                  setData({
                                    ...event,
                                    discord_sync: e.target.checked,
                                  });
                                }}
                                class="form-check-input"
                                type="checkbox"
                                checked={event.discord_sync}
                              />
                              <span class="form-check-label">
                                {
                                  container.localeManager.strings
                                    .syncWithDiscord
                                }
                              </span>
                            </label>
                          </Col>
                        </FormGroup>
                        {event.discord_sync &&
                          discordChannels &&
                          discordChannels.length > 0 && (
                            <FormGroup row>
                              <Col sm={4}>
                                <Label>
                                  {
                                    container.localeManager.strings
                                      .discordChannel
                                  }
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...event,
                                      discord_channel_id: e.target.value,
                                    })
                                  }
                                  value={event.discord_channel_id}
                                >
                                  <option></option>
                                  {discordChannels.map((m) => {
                                    return (
                                      <option value={m.id}>#{m.name}</option>
                                    );
                                  })}
                                </Input>
                                <FormText>
                                  {
                                    container.localeManager.strings
                                      .discordChannelInfo
                                  }
                                </FormText>
                              </Col>
                            </FormGroup>
                          )}

                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.information}
                          </Label>
                          <MDEditor
                            data-color-mode="dark"
                            height={500}
                            onChange={(value) =>
                              setData({ ...event, information: value })
                            }
                            value={event.information}
                          />
                        </FormGroup>
                        {event.discord_sync && (
                          <FormGroup>
                            <Label>
                              {
                                container.localeManager.strings
                                  .discordDescription
                              }
                            </Label>
                            <FormText className="muted mb-3">
                              {
                                container.localeManager.strings
                                  .discordDescriptionInfo
                              }
                            </FormText>
                            <MDEditor
                              data-color-mode="dark"
                              height={200}
                              onChange={(value) =>
                                setData({
                                  ...event,
                                  discord_event_description: value,
                                })
                              }
                              value={event.discord_event_description}
                            />
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Label>{container.localeManager.strings.rules}</Label>
                          <MDEditor
                            data-color-mode="dark"
                            height={500}
                            onChange={(value) =>
                              setData({ ...event, rules: value })
                            }
                            value={event.rules}
                          />
                        </FormGroup>
                        <FormGroup row>
                          <Col>
                            <Label>
                              {
                                container.localeManager.strings
                                  .voiceOrCommunicationLink
                              }
                            </Label>
                            <Input
                              type="url"
                              onChange={(e) =>
                                setData({
                                  ...event,
                                  voice_link: e.target.value,
                                })
                              }
                              value={event.voice_link}
                            ></Input>
                          </Col>
                          <Col>
                            <Label>
                              {container.localeManager.strings.website}
                            </Label>
                            <Input
                              type="url"
                              onChange={(e) =>
                                setData({ ...event, website: e.target.value })
                              }
                              value={event.website}
                            ></Input>
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <LoadingButton
                            color="primary"
                            size="sm"
                            onClick={save}
                          >
                            {container.localeManager.strings.save}
                          </LoadingButton>
                        </FormGroup>
                        {errors && errors.length > 0 && (
                          <ErrorBox errors={errors} />
                        )}
                      </TabPane>
                      {event && event.id != null && event.id > 0 && (
                        <TabPane tabId="slots">
                          <CompanyEventSlotsManagement
                            container={container}
                            event={event}
                          ></CompanyEventSlotsManagement>
                        </TabPane>
                      )}
                    </TabContent>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </>
  );
}
