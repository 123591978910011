import { useContext, useEffect, useState } from "react";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import {
  DrawerComponent,
  MenuComponent,
} from "../../common/plugins/metronic/components";
import truckyService from "../../common/services/trucky-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router";
import EnterpriseService from "../../common/services/enterprise-service";
import config from "../../config";
import Cookies from "js-cookie";
import { Button, Input, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { isTeam } from "../utils";

const Drawer = ({ container }) => {
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
  const active = false;
  const navigate = useNavigate();
  const es = new EnterpriseService();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.bootstrap();
      MenuComponent.bootstrap();
    });
  }, []);

  useEffect(() => {
    let instance = DrawerComponent.getInstance("kt_drawer_example_basic");
    if (instance) instance.hide();
  }, [location]);

  const logout = () => {
    truckyService.removeToken();
    setGlobalContext({ user: null, company: null });
    navigate("/");
  };

  const navigateToSearch = () => {
    if (searchTerm != "" && searchTerm.length >= 3)
      navigate(`/search?term=${searchTerm}`);
  };

  return (
    <div
      id="kt_drawer_example_basic"
      class="bg-secondary"
      data-kt-drawer="true"
      data-kt-drawer-direction="start"
      data-kt-drawer-activate="true"
      data-kt-drawer-width="350px"
      data-kt-drawer-toggle="#kt_drawer_example_basic_button"
      data-kt-drawer-close="#kt_drawer_example_basic_close"
    >
      <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
        <div className="app-sidebar-wrapper">
          <div id="kt_app_sidebar_menu_scroll" class="scroll-y my-5 mx-1">
            {!truckyService.getToken() && (
              <div className="mx-3">
                <Link
                  className="btn btn-primary w-100 d-flex align-items-center"
                  to="/login"
                >
                  <FontAwesomeIcon icon="user-lock"></FontAwesomeIcon>
                  {container.localeManager.strings.login}
                </Link>
              </div>
            )}
            {truckyService.getToken() && globalContext.member && (
              <div
                class="menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold"
                data-kt-menu="true"
              >
                <div
                  class="menu-item menu-sub-indention menu-accordion"
                  data-kt-menu-trigger="click"
                >
                  <a href="#" className="menu-link">
                    <span class="menu-icon w-auto">
                      <img
                        src={globalContext.member.avatar_url}
                        className="avatar h-50px rounded me-3"
                      />
                    </span>
                    <span class="menu-title">
                      <div class="d-flex flex-column">
                        <div class="fw-bolder d-flex align-items-center fs-5">
                          {globalContext.member.name}
                        </div>
                      </div>
                    </span>
                    <span class="menu-arrow"></span>
                  </a>
                  <div class="menu-sub menu-sub-accordion ps-5 mt-3">
                    {globalContext.member.email != null && (
                      <>
                        <Link
                          to="/my/profile"
                          className="menu-item pb-2 text-gray-700"
                        >
                          <span class="menu-icon">
                            <FontAwesomeIcon
                              icon="user"
                              fixedWidth
                            ></FontAwesomeIcon>
                          </span>
                          <span class="menu-title">
                            {container.localeManager.strings.profile}
                          </span>
                        </Link>
                        <Link
                          to="/my/applications"
                          className="menu-item pb-2 text-gray-700"
                        >
                          <span class="menu-icon">
                            <FontAwesomeIcon
                              icon="user-plus"
                              fixedWidth
                            ></FontAwesomeIcon>
                          </span>
                          <span class="menu-title">
                            {container.localeManager.strings.myApplications}
                          </span>
                        </Link>
                        <Link
                          to="/my/subscriptions"
                          className="menu-item pb-2 text-gray-700"
                        >
                          <span class="menu-icon">
                            <FontAwesomeIcon
                              icon="user"
                              fixedWidth
                            ></FontAwesomeIcon>
                          </span>
                          <span class="menu-title">
                            {container.localeManager.strings.subscriptions}
                          </span>
                        </Link>
                        <a
                          href={config.PATREON_AUTH_URL}
                          className="menu-item pb-2 text-gray-700"
                        >
                          <span class="menu-icon">
                            <FontAwesomeIcon
                              icon={["fab", "patreon"]}
                              fixedWidth
                            ></FontAwesomeIcon>
                          </span>
                          <span class="menu-title">
                            {container.localeManager.strings.connectWithPatreon}
                          </span>
                        </a>
                        {/** 
                                                {(container.dataService.data.enablePremiumFeature() || isTeam(globalContext.member)) &&
                                                    <Link to="/gamedashboard" className="menu-item pb-2 text-gray-700">
                                                        <span class="menu-icon">
                                                            <FontAwesomeIcon icon="dashboard" fixedWidth></FontAwesomeIcon>
                                                        </span>
                                                        <span class="menu-title text-premium">{container.localeManager.strings.mobileDashboard} <FontAwesomeIcon icon="star"></FontAwesomeIcon></span>
                                                    </Link>
                                                }
                                                */}
                        {/** 
                                                {!(container.dataService.data.enablePremiumFeature() || isTeam(globalContext.member)) &&
                                                    <Link to="/premium" className="menu-item pb-2 text-gray-700">
                                                        <span class="menu-icon">
                                                            <FontAwesomeIcon icon="dashboard" fixedWidth></FontAwesomeIcon>
                                                        </span>
                                                        <span class="menu-title text-premium">{container.localeManager.strings.mobileDashboard} <FontAwesomeIcon icon="star"></FontAwesomeIcon></span>
                                                    </Link>
                                                }
                                                */}
                      </>
                    )}
                    <Link
                      onClick={logout}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon
                          icon="sign-out"
                          fixedWidth
                        ></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.logout}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div
              class="menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold mt-5"
              data-kt-menu="true"
            >
              {globalContext.company == null && (
                <div className="menu-item">
                  <a
                    onClick={() => navigate("/register-your-company")}
                    className={classnames({
                      "menu-link": true,
                      active: active == "/register-your-company",
                    })}
                    title=""
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon icon="building"></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.registerYourCompany}
                    </span>
                    <span class="menu-arrow"></span>
                  </a>
                </div>
              )}
              {globalContext.company != null && (
                <div
                  class="menu-item menu-sub-indention menu-accordion"
                  data-kt-menu-trigger="click"
                >
                  <a href="#" className="menu-link">
                    <span class="menu-icon">
                      <FontAwesomeIcon icon="building"></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.vtcHub}
                    </span>
                    <span class="menu-arrow"></span>
                  </a>
                  <div class="menu-sub menu-sub-accordion ps-5 mt-3">
                    <a
                      onClick={() => navigate("/vtchub")}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon
                          icon="home"
                          fixedWidth
                        ></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.dashboard}
                      </span>
                    </a>
                    {es.can(globalContext.member, "company.*") && (
                      <a
                        onClick={() => navigate("/company/settings")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon
                            icon="cog"
                            fixedWidth
                          ></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.companySettings}
                        </span>
                      </a>
                    )}
                    <a
                      onClick={() => navigate("/company/members")}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon
                          icon="users"
                          fixedWidth
                        ></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.members}
                      </span>
                    </a>
                    {es.can(globalContext.member, "members.manage_roles") && (
                      <a
                        onClick={() => navigate("/company/roles")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon
                            icon="user-tag"
                            fixedWidth
                          ></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.roles}
                        </span>
                      </a>
                    )}
                    {es.can(globalContext.member, "members.manage_roles") && (
                      <a
                        onClick={() => navigate("/company/ranks")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon
                            icon="ranking-star"
                            fixedWidth
                          ></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.ranks}
                        </span>
                      </a>
                    )}
                    {es.can(
                      globalContext.member,
                      "members.manage_applications"
                    ) && (
                      <a
                        onClick={() => navigate("/applications")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon
                            icon="user-plus"
                            fixedWidth
                          ></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.applications}
                        </span>
                      </a>
                    )}
                    <a
                      onClick={() => navigate("/jobs")}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon icon="suitcase"></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.jobs}
                      </span>
                    </a>
                    {globalContext.company != null &&
                      (globalContext.company.company_type == "realistic" ||
                        globalContext.company.company_type == "both") && (
                        <>
                          <a
                            onClick={() => navigate("/balance")}
                            className="menu-item pb-2 text-gray-700"
                          >
                            <span class="menu-icon">
                              <FontAwesomeIcon icon="piggy-bank"></FontAwesomeIcon>
                            </span>
                            <span class="menu-title">
                              {container.localeManager.strings.balance}
                            </span>
                          </a>
                          <a
                            onClick={() => navigate("/garages")}
                            className="menu-item pb-2 text-gray-700"
                          >
                            <span class="menu-icon">
                              <FontAwesomeIcon icon="warehouse"></FontAwesomeIcon>
                            </span>
                            <span class="menu-title">
                              {container.localeManager.strings.garages}
                            </span>
                          </a>
                          <a
                            onClick={() => navigate("/vehicles")}
                            className="menu-item pb-2 text-gray-700"
                          >
                            <span class="menu-icon">
                              <FontAwesomeIcon icon="truck"></FontAwesomeIcon>
                            </span>
                            <span class="menu-title">
                              {container.localeManager.strings.fleet}
                            </span>
                          </a>
                          <a
                            onClick={() => navigate("/maintenances")}
                            className="menu-item pb-2 text-gray-700"
                          >
                            <span class="menu-icon">
                              <FontAwesomeIcon icon="wrench"></FontAwesomeIcon>
                            </span>
                            <span class="menu-title">
                              {container.localeManager.strings.maintenance}
                            </span>
                          </a>
                        </>
                      )}
                    <a
                      onClick={() => navigate("/stats")}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon icon="table"></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.stats}
                      </span>
                    </a>
                    <a
                      onClick={() => navigate("/company/events/calendar")}
                      className="menu-item pb-2 text-gray-700"
                    >
                      <span class="menu-icon">
                        <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                      </span>
                      <span class="menu-title">
                        {container.localeManager.strings.calendar}
                      </span>
                    </a>
                    {es.can(globalContext.member, "events.manage") && (
                      <a
                        onClick={() => navigate("/company/events")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.events}
                        </span>
                      </a>
                    )}
                    {es.can(globalContext.member, "feeds.manage") && (
                      <a
                        onClick={() => navigate("/feeds/manage")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon icon="newspaper"></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.newsFeed}
                        </span>
                      </a>
                    )}
                    {es.can(globalContext.member, "achievements.manage") && (
                      <a
                        onClick={() => navigate("/company/achievements")}
                        className="menu-item pb-2 text-gray-700"
                      >
                        <span class="menu-icon">
                          <FontAwesomeIcon icon="trophy"></FontAwesomeIcon>
                        </span>
                        <span class="menu-title">
                          {container.localeManager.strings.achievements}
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              )}
              <div className="menu-item">
                <a
                  onClick={() => navigate("/directory")}
                  className={classnames({
                    "menu-link": true,
                    active: active == "/directory",
                  })}
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-dismiss="click"
                  data-bs-placement="right"
                >
                  <span class="menu-icon">
                    <FontAwesomeIcon icon="database"></FontAwesomeIcon>
                  </span>
                  <span class="menu-title">
                    {container.localeManager.strings.vtcDirectory}
                  </span>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={() => navigate("/leaderboards")}
                  className={classnames({
                    "menu-link": true,
                    active: active == "/leaderboards",
                  })}
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-dismiss="click"
                  data-bs-placement="right"
                >
                  <span class="menu-icon">
                    <FontAwesomeIcon icon="database"></FontAwesomeIcon>
                  </span>
                  <span class="menu-title">
                    {container.localeManager.strings.leaderboards}
                  </span>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={() => navigate("/hosting")}
                  className={classnames({
                    "menu-link": true,
                    active: active == "/hosting",
                  })}
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-dismiss="click"
                  data-bs-placement="right"
                >
                  <span class="menu-icon">
                    <FontAwesomeIcon icon="chalkboard-user"></FontAwesomeIcon>
                  </span>
                  <span class="menu-title">
                    {container.localeManager.strings.dedicatedServers}
                  </span>
                </a>
              </div>
              <div className="menu-item">
                <a
                  onClick={() => navigate("/events")}
                  className={classnames({
                    "menu-link": true,
                    active: active == "/events",
                  })}
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  data-bs-dismiss="click"
                  data-bs-placement="right"
                >
                  <span class="menu-icon">
                    <FontAwesomeIcon icon="calendar"></FontAwesomeIcon>
                  </span>
                  <span class="menu-title">
                    {container.localeManager.strings.events}
                  </span>
                </a>
              </div>
              <div
                class="menu-item menu-sub-indention menu-accordion"
                data-kt-menu-trigger="click"
              >
                <a href="#" className="menu-link">
                  <span class="menu-icon">
                    <FontAwesomeIcon icon="building"></FontAwesomeIcon>
                  </span>
                  <span class="menu-title">TruckersMP</span>
                  <span class="menu-arrow"></span>
                </a>
                <div class="menu-sub menu-sub-accordion ps-5 pt-3">
                  <a
                    onClick={() => navigate("/servers")}
                    className="menu-item text-gray-700 pb-2"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="server"
                        fixedWidth
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.routeServersTitle}
                    </span>
                  </a>
                  <a
                    onClick={() => navigate("/traffic")}
                    className="menu-item text-gray-700 pb-2"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="traffic-light"
                        fixedWidth
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.traffic}
                    </span>
                  </a>
                  <a
                    onClick={() => navigate("/map")}
                    className="menu-item text-gray-700 pb-2"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon icon="map" fixedWidth></FontAwesomeIcon>
                    </span>
                    <span class="menu-title">
                      {container.localeManager.strings.map}
                    </span>
                  </a>
                </div>
              </div>
              {!container.dataService.data.enablePremiumFeature() && (
                <div className="menu-item mt-5">
                  <a
                    onClick={() => navigate("/premium")}
                    className={classnames({
                      "menu-link": true,
                      active: active == "/premium",
                    })}
                    title=""
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-dismiss="click"
                    data-bs-placement="right"
                  >
                    <span class="menu-icon">
                      <FontAwesomeIcon
                        icon="star"
                        className="text-premium"
                      ></FontAwesomeIcon>
                    </span>
                    <span class="menu-title text-premium">Go Premium!</span>
                  </a>
                </div>
              )}
            </div>
            <div className="mt-5 mx-3">
              <InputGroup>
                <Input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={
                    container.localeManager.strings.searchForPlayerName
                  }
                  style={{ width: 250 }}
                  onKeyDown={(e) =>
                    e.key == "Enter" ? navigateToSearch() : true
                  }
                ></Input>
                <Button color="primary" onClick={navigateToSearch}>
                  <FontAwesomeIcon
                    icon="search"
                    className="mx-0"
                  ></FontAwesomeIcon>
                </Button>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
