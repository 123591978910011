import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import VTCMenu from "../../web/components/VTCMenu";
import LoadingButton from "../ui/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";

const Roles = ({ container }) => {
  const [roles, setRoles] = useState([]);
  const { globalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();
  const [currentRole, setCurrentRole] = useState(null);
  const [showEditDialog, toggleEditDialog] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const company = globalContext.company;
  const member = globalContext.member;

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRoles(company);
      if (!result.message) setRoles(result);
    }
  };

  const getRoleColoredSpan = (role) => {
    return <span style={{ color: role.color }}>{role.name}</span>;
  };

  useEffect(() => {
    if (!showEditDialog) {
      setCurrentRole(null);
    }
  }, [showEditDialog]);

  useEffect(() => {
    if (currentRole != null) {
      toggleEditDialog(true);
    }
  }, [currentRole]);

  const [discordRoles, setDiscordRoles] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const getPermissions = async () => {
    const result = await es.getPermissions();

    // TO-DO: Remove this when permissions are implemented
    /*result.push({
      id: 99,
      name: "Manage Events",
      capabilities: "[\"events.manage\"]"
    });
    */

    setPermissions(result);
  };

  useEffect(() => {
    getDiscordRoles();
    getPermissions();
  }, []);

  const getDiscordRoles = async () => {
    try {
      const result = await es.getCompanyDiscordServerRoles(company);

      if (result.success) {
        setDiscordRoles(result.data);
      }
    } catch (error) {}
  };

  const saveRole = async () => {
    setButtonIsLoading(true);
    var result = null;

    if (currentRole.id) {
      result = await es.updateRole(company, currentRole);
    } else {
      result = await es.createRole(company, currentRole);
    }

    if (result.success) {
      toast.success(container.localeManager.strings.roleSaved);
      toggleEditDialog(false);
      getRoles();
    } else {
      toast.error(result.message);
    }
    setButtonIsLoading(false);
  };

  const togglePermission = (permission, added) => {
    var perms = [];

    if (currentRole.permissions) perms = currentRole.permissions;

    if (added) {
      perms.push(permission);
    } else {
      perms = perms.filter((m) => m.id != permission.id);
    }

    setCurrentRole({ ...currentRole, permissions: perms });
  };

  const deleteRole = async (role) => {
    if (
      await confirmWrapper(container.localeManager.strings.deleteRoleConf, {
        container: container,
      })
    ) {
      const result = await es.deleteRole(company, role);
      if (result.success) {
        getRoles();
      } else {
        toast.error(result.message);
      }
    }
  };

  return (
    <Row noGutters>
      <Col sm="auto">
        <VTCMenu active={"/company/roles"} container={container}></VTCMenu>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <Breadcrumbs
              breadcrumbs={[
                {
                  to: "/vtchub",
                  title: container.localeManager.strings.vtcHub,
                },
                {
                  to: "/company/members",
                  title: container.localeManager.strings.members,
                },
                {
                  title: container.localeManager.strings.roles,
                },
              ]}
            ></Breadcrumbs>
            <h5 className="d-flex w-100 mb-5 justify-content-between align-items-center">
              <div>{container.localeManager.strings.roles}</div>
              <div>
                {es.can(member, "members.manage_roles") && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => setCurrentRole({})}
                  >
                    <FontAwesomeIcon icon="add"></FontAwesomeIcon>{" "}
                    {container.localeManager.strings.addRole}
                  </Button>
                )}
              </div>
            </h5>
            <Table
              responsive
              striped
              size="sm"
              className="table-row-dashed table-row-gray-300 align-middle gs-0"
              hover
            >
              <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                  <th scope="col" className="ps-4">
                    {container.localeManager.strings.name}
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {roles.map((m) => {
                  return (
                    <tr>
                      <td className="ps-2">{getRoleColoredSpan(m)}</td>
                      <td className="text-end">
                        <Button
                          title={container.localeManager.strings.edit}
                          onClick={() => setCurrentRole(m)}
                          className="btn-icon w-30px h-30px ms-auto"
                          size="sm"
                          color="active-light-primary"
                        >
                          <FontAwesomeIcon
                            icon="pencil-alt"
                            className="me-0"
                          ></FontAwesomeIcon>
                        </Button>
                        <Button
                          title={container.localeManager.strings.delete}
                          disabled={m.owner}
                          className="btn-icon w-30px h-30px ms-auto"
                          onClick={() => deleteRole(m)}
                          size="sm"
                          color="active-light-danger"
                        >
                          <FontAwesomeIcon
                            icon="trash-alt"
                            className="me-0"
                          ></FontAwesomeIcon>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {showEditDialog && (
              <Modal
                size="lg"
                className="big-modal"
                wrapClassName="big-modal-wrap"
                modalClassName="big-modal-container"
                contentClassName="big-modal-content"
                isOpen={showEditDialog}
                toggle={() => toggleEditDialog(false)}
              >
                <ModalHeader
                  toggle={() => toggleEditDialog(false)}
                  close={
                    <Button
                      color="active-light-primary"
                      className="btn-icon btn-sm w-30px h-30px ms-auto"
                      onClick={() => toggleEditDialog(false)}
                    >
                      <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                    </Button>
                  }
                >
                  {container.localeManager.strings.rolesManagement}
                </ModalHeader>
                <ModalBody>
                  {currentRole != null && (
                    <div>
                      <FormGroup row>
                        <Col>
                          <Label>{container.localeManager.strings.name}</Label>
                          <Input
                            type="text"
                            placeholder="Role Name"
                            value={currentRole.name}
                            onChange={(e) =>
                              setCurrentRole({
                                ...currentRole,
                                name: e.target.value,
                              })
                            }
                          ></Input>
                        </Col>
                        <Col>
                          <Label>{container.localeManager.strings.order}</Label>
                          <Input
                            type="number"
                            min="0"
                            placeholder="Order"
                            value={currentRole.order}
                            onChange={(e) =>
                              setCurrentRole({
                                ...currentRole,
                                order: e.target.value,
                              })
                            }
                          ></Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col>
                          <Label>{container.localeManager.strings.color}</Label>
                          <Input
                            type="color"
                            placeholder={
                              container.localeManager.strings.roleColor
                            }
                            value={currentRole.color}
                            onChange={(e) =>
                              setCurrentRole({
                                ...currentRole,
                                color: e.target.value,
                              })
                            }
                          ></Input>
                        </Col>
                        <Col>
                          {(company.company_type == "realistic" ||
                            company.company_type == "both") && (
                            <FormGroup>
                              <Label>
                                {container.localeManager.strings.roleSalary}
                              </Label>
                              <InputGroup>
                                <Input
                                  type="number"
                                  min="0"
                                  step="any"
                                  value={currentRole.additional_member_salary}
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      additional_member_salary: e.target.value,
                                    })
                                  }
                                ></Input>
                                <InputGroupText>
                                  {company.salary_type == "revenue_percentage"
                                    ? "%"
                                    : "T¢"}
                                </InputGroupText>
                              </InputGroup>
                              <FormText color="muted">
                                {container.localeManager.strings.roleSalaryDesc}
                                <br />
                                {
                                  container.localeManager.strings
                                    .totalSalaryPerRole
                                }{" "}
                                {parseFloat(company.base_member_salary) +
                                  parseFloat(
                                    currentRole.additional_member_salary
                                  )}{" "}
                                {company.salary_type == "revenue_percentage"
                                  ? "%"
                                  : "T¢"}
                              </FormText>
                            </FormGroup>
                          )}
                        </Col>
                      </FormGroup>
                      {discordRoles &&
                        discordRoles.bot_in_server &&
                        discordRoles.roles && (
                          <FormGroup row>
                            <Col>
                              <Label>Discord Role</Label>
                              <Input
                                type="select"
                                value={currentRole.discord_role_id}
                                onChange={(e) =>
                                  setCurrentRole({
                                    ...currentRole,
                                    discord_role_id: e.target.value,
                                  })
                                }
                              >
                                <option value={null}></option>
                                {discordRoles.roles.map((m) => {
                                  return <option value={m.id}>{m.name}</option>;
                                })}
                              </Input>
                            </Col>
                            <Col></Col>
                          </FormGroup>
                        )}
                      {!currentRole.owner && (
                        <>
                          <FormGroup row>
                            <Col>
                              <label class="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      inactive: e.target.checked,
                                    })
                                  }
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={currentRole.inactive}
                                />
                                <span class="form-check-label">
                                  {container.localeManager.strings.inactive}
                                </span>
                              </label>
                            </Col>
                            <Col>
                              <label class="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      new_member_default_role: e.target.checked,
                                    })
                                  }
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={currentRole.new_member_default_role}
                                />
                                <span class="form-check-label">
                                  {
                                    container.localeManager.strings
                                      .new_member_default_role
                                  }
                                </span>
                              </label>
                              <div className="form-text text-muted">
                                {
                                  container.localeManager.strings
                                    .new_member_default_role_hint
                                }
                              </div>
                            </Col>
                          </FormGroup>
                        </>
                      )}
                      {!currentRole.owner && (
                        <div>
                          <h5>{container.localeManager.strings.permissions}</h5>
                          <FormGroup row>
                            {permissions.map((m) => {
                              return (
                                <Col sm={12} md={6} className="mb-3">
                                  <label class="form-check form-switch form-check-custom form-check-solid">
                                    <input
                                      onChange={(e) =>
                                        togglePermission(
                                          m,
                                          e.currentTarget.checked
                                        )
                                      }
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={
                                        currentRole.permissions
                                          ? currentRole.permissions.find(
                                              (p) => p.id == m.id
                                            ) != null
                                          : false
                                      }
                                    />
                                    <span class="form-check-label">
                                      {m.name}
                                    </span>
                                  </label>
                                </Col>
                              );
                            })}
                          </FormGroup>
                        </div>
                      )}
                      <FormGroup className="mt-3">
                        <LoadingButton
                          size="sm"
                          loading={buttonIsLoading}
                          color="primary"
                          onClick={() => saveRole()}
                        >
                          {container.localeManager.strings.save}
                        </LoadingButton>
                      </FormGroup>
                    </div>
                  )}
                </ModalBody>
              </Modal>
            )}
          </CardBody>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Roles;
