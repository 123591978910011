import { Button, ButtonGroup, Card, CardBody, Col, Row } from "reactstrap";
import { EventsCalendar } from "../components/events/EventsCalendar";
import NitroAdSidebar from "../../ads/NitroAdSidebar";
import Calendar from "../components/common/Calendar";
import { useContext, useEffect, useState } from "react";
import truckyService from "../../common/services/trucky-service";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import UserUpcomingEvents from "../components/events/UserUpcomingEvents";

export default function EventsCalendarPage({ container }) {
  const { globalContext } = useContext(EnterpriseContext);
  const [tab, setTab] = useState("public");

  useEffect(() => {
    truckyService.setDocumentTitle("Events");
  }, []);

  return (
    <Row>
      <Col>
        {globalContext.member && (
          <div className="w-100">
            <ButtonGroup className="w-100">
              <Button
                color={tab == "public" ? "success" : "primary"}
                onClick={() => setTab("public")}
              >
                Public Events
              </Button>
              <Button
                color={tab == "my" ? "success" : "primary"}
                onClick={() => setTab("my")}
              >
                My Upcoming Events
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div className="mt-3">
          {tab === "public" && <EventsCalendar container={container} />}
          {tab === "my" && <UserUpcomingEvents container={container} />}
        </div>
      </Col>
      <div className="ad-column-placeholder">
        {!container.dataService.data.enablePremiumFeature() && (
          <NitroAdSidebar container={container}></NitroAdSidebar>
        )}
      </div>
    </Row>
  );
}
