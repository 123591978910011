import { Tooltip } from "reactstrap";

const CustomBadge = ({
  color,
  size,
  className = "",
  children,
  style,
  square = false,
  light = true,
  title,
}) => {
  if (color == "white") className += " border";

  return (
    <span
      title={title}
      className={`badge badge${light ? "-light" : ""}${
        color == "" ? "" : "-"
      }${color} ${square ? "badge-square" : ""} ${
        size ? `badge-${size}` : ""
      } ${className}`}
      style={style}
    >
      {children}
    </span>
  );
};

export default CustomBadge;
