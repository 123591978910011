import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { getAddToGoogleCalendarURL, getZonedTimeFormatted } from "../utils";
import NitroAdSidebar from "../../ads/NitroAdSidebar";
import { Link } from "react-router-dom";
import ServerStatusBadge from "../../components/hosting/ServerStatusBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingOverlay from "react-loading-overlay";
import CustomBadge from "../../components/ui/CustomBadge";
import { toast, ToastContainer } from "react-toastify";
import LazyImage from "../components/common/LazyImage";
import truckyService from "../../common/services/trucky-service";
import LoadingButton from "../../components/ui/LoadingButton";

export default function EventDetail({ container }) {
  const { id } = useParams();
  const es = new EnterpriseService();
  const [event, setEvent] = useState(null);
  const { globalContext } = useContext(EnterpriseContext);
  const [cannotAccessEvent, setCannoAccessEvent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    truckyService.setDocumentTitle("Event Detail");
    getEvent();
  }, []);

  const getEvent = async () => {
    setLoading(true);
    const result = await es.getCompanyEvent(id);

    if (result.id) {
      setEvent(result);
      truckyService.setDocumentTitle(result.title);
    } else {
      setCannoAccessEvent(true);
    }
    setLoading(false);
  };

  const userAttend = async () => {
    await es.userAttendCompanyEvent(event.id);
    getEvent();
  };

  const userUnattend = async () => {
    await es.userUnattendCompanyEvent(event.id);
    getEvent();
  };

  const companyAttend = async () => {
    await es.companyAttendCompanyEvent(event.id);
    getEvent();
  };

  const companyUnattend = async () => {
    await es.companyUnattendCompanyEvent(event.id);
    getEvent();
  };

  const companyHasBookedSlot = () => {
    return event.slots.find((m) => m.company_id == globalContext.company.id);
  };

  const allSlotsBooked = () => {
    return (
      event.slots.filter((m) => m.company_id != null).length ==
      event.slots.length
    );
  };

  const bookASlot = async () => {
    const slot_id = document.getElementById("slot_id").value;

    if (slot_id && slot_id != "") {
      setLoading(true);
      const result = await es.bookCompanyEventSlot(event.id, slot_id);
      if (result.success) {
        toast.success("Slot booked");
        getEvent();
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  const unbookSlot = async () => {
    setLoading(true);
    const result = await es.unbookCompanyEventSlot(event.id);
    if (result.success) {
      toast.success("Slot unbooked");
      getEvent();
    } else {
      toast.error(result.message);
    }
    setLoading(false);
  };

  const generateICSFile = () => {
    // Generate a unique identifier for the event (e.g., timestamp and a random string)
    const uid = `${new Date().getTime()}@truckyapp.com`;
    const timestamp = new Date().toISOString();

    // Create the iCalendar content
    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:Trucky
BEGIN:VEVENT
UID:${uid}
DTSTAMP:${moment.utc(timestamp).format("YYYYMMDDTHHmmss")}Z
SUMMARY:${event.title}
DESCRIPTION:${event.start_city} - ${event.start_location} - ${event.game.name}
LOCATION:${event.start_city}
DTSTART:${moment.utc(event.start_date).format("YYYYMMDDTHHmmss")}Z
DTEND:${moment.utc(event.start_date).add(4, "hours").format("YYYYMMDDTHHmmss")}Z
END:VEVENT
END:VCALENDAR
    `.trim();

    // Create a Blob and download the file
    const blob = new Blob([icsContent], { type: "text/calendar" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${event.slug}.ics`;
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row>
          <Col>
            {cannotAccessEvent && (
              <div className="alert alert-danger text-center fs-1">
                <FontAwesomeIcon
                  icon="exclamation-triangle"
                  size="3x"
                ></FontAwesomeIcon>
                <div className="my-10">
                  {container.localeManager.strings.thisIsAPrivateEvent}
                </div>
              </div>
            )}
            {event && (
              <>
                <div
                  class="event-hero-image rounded m-1"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${event.cover_url}"`,
                  }}
                >
                  <div class="hero-text">
                    <h1 style={{ fontSize: "3rem" }}>{event.title}</h1>
                    <div className="fs-2 mt-3">
                      <span id="start_date">
                        {getZonedTimeFormatted(event.start_date, "PPPP @ p OOO")}
                      </span>
                      <UncontrolledTooltip target="start_date">
                        {container.localeManager.strings.inYourLocalTimezone}
                      </UncontrolledTooltip>
                    </div>
                    <div className="fs-3 mt-3">
                      <CustomBadge
                        title="Users attending"
                        color="success"
                        size="lg"
                        className="me-3"
                      >
                        <FontAwesomeIcon
                          icon="users"
                          className="me-1"
                        ></FontAwesomeIcon>{" "}
                        {event.users_attending.length}
                      </CustomBadge>
                      <CustomBadge
                        title="Companies attending"
                        color="success"
                        size="lg"
                      >
                        <FontAwesomeIcon
                          icon="building"
                          className="me-1"
                        ></FontAwesomeIcon>{" "}
                        {event.companies_attending.length}
                      </CustomBadge>
                    </div>
                    <div className="mt-5">
                      {globalContext.member &&
                        !event.users_attending.find(
                          (m) => m.id == globalContext.member.id
                        ) && (
                          <>
                            <Button
                              onClick={userAttend}
                              color="success"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="user"></FontAwesomeIcon>
                              {container.localeManager.strings.iWillBeThere}
                            </Button>
                          </>
                        )}
                      {globalContext.member &&
                        event.users_attending.find(
                          (m) => m.id == globalContext.member.id
                        ) && (
                          <>
                            <LoadingButton
                              color="danger"
                              onClick={userUnattend}
                              size="sm"
                            >
                              <FontAwesomeIcon icon="user"></FontAwesomeIcon>{" "}
                              {container.localeManager.strings.iWillNotBeThere}
                            </LoadingButton>
                          </>
                        )}
                      {globalContext.member &&
                        globalContext.company &&
                        es.can(
                          globalContext.member,
                          "events.canAttendForTheCompany"
                        ) &&
                        !event.companies_attending.find(
                          (m) => m.id == globalContext.company.id
                        ) && (
                          <>
                            <LoadingButton
                              onClick={companyAttend}
                              color="success"
                              className="ms-3"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="building"></FontAwesomeIcon>{" "}
                              {
                                container.localeManager.strings
                                  .myCompanyWillBeThere
                              }
                            </LoadingButton>
                          </>
                        )}
                      {globalContext.company &&
                        globalContext.member &&
                        es.can(
                          globalContext.member,
                          "events.canAttendForTheCompany"
                        ) &&
                        event.companies_attending.find(
                          (m) => m.id == globalContext.company.id
                        ) && (
                          <>
                            <LoadingButton
                              onClick={companyUnattend}
                              color="danger"
                              className="ms-3"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="building"></FontAwesomeIcon>{" "}
                              {
                                container.localeManager.strings
                                  .myCompanyWillNotBeThere
                              }
                            </LoadingButton>
                          </>
                        )}

                      <div>
                        {event.external_id && (
                          <Button
                            color="secondary"
                            size="sm"
                            tag="a"
                            href={event.external_url}
                            target="_blank"
                            className="mt-3"
                          >
                            {
                              container.localeManager.strings
                                .truckersMPEventPage
                            }
                            <FontAwesomeIcon
                              icon="external-link-alt"
                              className="ms-2 me-0"
                            ></FontAwesomeIcon>
                          </Button>
                        )}
                        {event.user_id == globalContext.member?.id &&
                          event.company_id == globalContext.company?.id && (
                            <Link
                              to={`/company/events/manage/edit/${event.id}`}
                              className="btn btn-primary btn-sm ms-3 mt-3"
                            >
                              <FontAwesomeIcon
                                icon="pencil"
                                className="me-2"
                              ></FontAwesomeIcon>{" "}
                              {container.localeManager.strings.editEvent}
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <Card className="mt-3">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <h5>{container.localeManager.strings.details}</h5>
                      <div>
                        <Button
                          size="sm"
                          onClick={() => {
                            window.open(
                              getAddToGoogleCalendarURL(event),
                              "_blank"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fab", "google"]}
                          ></FontAwesomeIcon>{" "}
                          {container.localeManager.strings.addToGoogleCalendar}
                        </Button>
                        <Button
                          onClick={generateICSFile}
                          className="ms-3"
                          size="sm"
                        >
                          <FontAwesomeIcon icon="calendar-check"></FontAwesomeIcon>
                          {container.localeManager.strings.downloadICS}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <div className="event-description-row">
                        <div className="label">
                          {container.localeManager.strings.organizer}
                        </div>
                        <div className="value">
                          <img
                            src={event.author?.avatar_url}
                            className="h-40px rounded me-2"
                          />
                          <Link
                            to={`/user/${event.author?.id}`}
                            className="text-white"
                          >
                            {event.author?.name}
                          </Link>
                        </div>
                      </div>
                      <div className="event-description-row">
                        <div className="label">
                          {container.localeManager.strings.company}
                        </div>
                        <div className="value">
                          <LazyImage
                            src={event.company?.avatar_url}
                            className="h-40px rounded me-2"
                            placeholder="/assets/company-placeholder.png"
                            alt={event.company?.name}
                            title={event.company?.name}
                          ></LazyImage>
                          <Link
                            to={`/company/${event.company?.id}`}
                            className="text-white"
                          >
                            {event.company?.name}
                          </Link>
                        </div>
                      </div>
                      <div className="event-description-row">
                        <div className="label">
                          {container.localeManager.strings.platform}
                        </div>
                        <div className="value">
                          {container.localeManager.strings[event.mode]}
                        </div>
                      </div>
                      {event.meetup_date && (
                        <div className="event-description-row">
                          <div className="label">
                            {container.localeManager.strings.meetupTime}
                          </div>
                          <div className="value">
                            <span id="meetup_date">
                              {getZonedTimeFormatted(
                                event.meetup_date,
                                "PPPP @ p OOO"
                              )}{" "}
                            </span>
                            ({moment.utc(event.meetup_date).format("HH:mm")} UTC)
                            <UncontrolledTooltip target="meetup_date">
                              {
                                container.localeManager.strings
                                  .inYourLocalTimezone
                              }
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      )}
                      <div className="event-description-row">
                        <div className="label">
                          {container.localeManager.strings.location}
                        </div>
                        <div className="value">
                          {event.start_city} ({event.start_location})
                        </div>
                      </div>
                      {event.end_city && event.end_city != "" && (
                        <div className="event-description-row">
                          <div className="label">
                            {container.localeManager.strings.destination}
                          </div>
                          <div className="value">
                            {event.end_city} ({event.end_location})
                          </div>
                        </div>
                      )}
                      <div className="event-description-row">
                        <div className="label">
                          {container.localeManager.strings.mainLanguage}
                        </div>
                        <div className="value">{event.language}</div>
                      </div>
                      {event.server && event.server != "" && (
                        <div className="event-description-row">
                          <div className="label">Server</div>
                          <div className="value">{event.server}</div>
                        </div>
                      )}
                      {event.event_type == "convoy" &&
                        event.dedicated_server &&
                        event.dedicated_server_configuration_id && (
                          <div className="event-description-row">
                            <div className="label">Convoy Server</div>
                            <div className="value">
                              <div>{event.dedicated_server.lobby_name}</div>
                              <ServerStatusBadge
                                container={container}
                                server={event.dedicated_server}
                              ></ServerStatusBadge>
                            </div>
                          </div>
                        )}
                      {event.voice_link && event.voice_link != "" && (
                        <div className="event-description-row">
                          <div className="label">
                            {
                              container.localeManager.strings
                                .voiceOrCommunicationLink
                            }
                          </div>
                          <div className="value">
                            <a target="_blank" href={event.voice_link}>
                              {event.voice_link}
                            </a>
                          </div>
                        </div>
                      )}
                      {event.website && event.website != "" && (
                        <div className="event-description-row">
                          <div className="label">
                            {container.localeManager.strings.website}
                          </div>
                          <div className="value">
                            <a target="_blank" href={event.website}>
                              {event.website}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>

                <Card className="mt-3">
                  <CardBody>
                    <h5>{container.localeManager.strings.information}</h5>
                    <ReactMarkdown
                      children={event.information}
                      remarkPlugins={[remarkGfm]}
                    />
                  </CardBody>
                </Card>
                {event.route_url && (
                  <Card className="mt-3">
                    <CardBody>
                      <h5>{container.localeManager.strings.route}</h5>
                      <img src={event.route_url} className="w-100" />
                    </CardBody>
                  </Card>
                )}
                {event.rules && event.rules != "" && (
                  <Card className="mt-3">
                    <CardBody>
                      <h5>{container.localeManager.strings.rules}</h5>
                      <ReactMarkdown
                        children={event.rules}
                        remarkPlugins={[remarkGfm]}
                      />
                    </CardBody>
                  </Card>
                )}
                {event.slots && event.slots.length > 0 && (
                  <Card className="mt-3">
                    <CardBody>
                      {globalContext.company &&
                        globalContext.member &&
                        es.can(globalContext.member, "events.canBookSlot") && (
                          <>
                            {companyHasBookedSlot() && (
                              <Alert color="success">
                                <p>
                                  {
                                    container.localeManager.strings
                                      .alreadyBooked
                                  }
                                </p>
                                <hr />
                                <p classmName="mt-5">
                                  <Button
                                    color="danger"
                                    onClick={unbookSlot}
                                    size="sm"
                                  >
                                    {container.localeManager.strings.unbookSlot}
                                  </Button>
                                </p>
                              </Alert>
                            )}
                            {allSlotsBooked() && (
                              <Alert color="warning">
                                {container.localeManager.strings.allSlotsBooked}
                              </Alert>
                            )}
                            {!companyHasBookedSlot() && !allSlotsBooked() && (
                              <>
                                <h5 className="mb-3">
                                  {container.localeManager.strings.bookASlot}
                                </h5>
                                <div className="d-flex w-500px">
                                  <Input id="slot_id" type="select">
                                    <option></option>
                                    {event.slots.map((slot) => (
                                      <option
                                        disabled={slot.company_id != null}
                                        key={slot.id}
                                        value={slot.id}
                                      >
                                        {slot.name}{" "}
                                        {slot.company_id != null &&
                                          ` - ${container.localeManager.strings.booked}`}
                                      </option>
                                    ))}
                                  </Input>
                                  <Button
                                    onClick={bookASlot}
                                    color="primary"
                                    size="sm"
                                    className="ms-3"
                                  >
                                    {container.localeManager.strings.bookASlot}
                                  </Button>
                                </div>
                                <div className="mt-3">
                                  {
                                    container.localeManager.strings
                                      .bookASlotInfo
                                  }
                                </div>
                              </>
                            )}
                          </>
                        )}
                      {event.slots.filter(
                        (m) => m.company != null && m.confirmed
                      ).length > 0 && (
                        <>
                          <h5 className="my-3">
                            {container.localeManager.strings.bookedCompanies}
                          </h5>
                          <div className="grid" style={{ gap: "5px" }}>
                            {event.slots
                              .filter((m) => m.company != null && m.confirmed)
                              .map((slot) => (
                                <div className="border rounded p-3 g-col-12 g-col-md-6 g-col-lg-3 d-flex align-items-center">
                                  <LazyImage
                                    src={slot.company.avatar_url}
                                    className="h-50px rounded me-2"
                                    placeholder="/assets/company-placeholder.png"
                                    alt={slot.company.name}
                                    title={slot.company.name}
                                  ></LazyImage>
                                  <div>
                                    <Link
                                      to={`/company/${slot.company.id}`}
                                      className="text-white"
                                    >
                                      {slot.company.name}
                                    </Link>
                                    <div className="mt-2 text-success">
                                      {slot.name}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                )}
                {event.companies_attending.length > 0 && (
                  <Card className="mt-3">
                    <CardBody>
                      <h5 className="mb-3">
                        {container.localeManager.strings.companiesAttending}
                      </h5>
                      <div className="grid" style={{ gap: "5px" }}>
                        {event.companies_attending.map((c) => (
                          <div className="border rounded p-3 g-col-12 g-col-md-6 g-col-lg-3">
                            <img
                              src={c.avatar_url}
                              className="h-50px rounded me-2"
                            />
                            <Link
                              to={`/company/${c.id}`}
                              className="text-white"
                            >
                              {c.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                )}
                {event.users_attending.length > 0 && (
                  <Card className="mt-3">
                    <CardBody>
                      <h5 className="mb-3">
                        {container.localeManager.strings.usersAttending}
                      </h5>
                      <div className="grid" style={{ gap: "5px" }}>
                        {event.users_attending.map((m) => (
                          <div className="border rounded p-3 g-col-12 g-col-md-6 g-col-lg-3">
                            <img
                              src={m.avatar_url}
                              className="h-50px rounded me-2"
                            />
                            <Link to={`/user/${m.id}`} className="text-white">
                              {m.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                )}
              </>
            )}
          </Col>
          <div className="ad-column-placeholder">
            {!container.dataService.data.enablePremiumFeature() && (
              <NitroAdSidebar container={container}></NitroAdSidebar>
            )}
          </div>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </div>
  );
}
