import { useState } from "react";
import { Button, ButtonGroup, Input, Label } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingButton from "../ui/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CompanyEventSingleSlot({
  container,
  event,
  slot,
  index,
  reload,
  companies = [],
}) {
  const [localSlot, setLocalSlot] = useState(slot);
  const es = new EnterpriseService();

  const saveSlot = async () => {
    const result = await es.updateCompanyEventSlot(event.id, localSlot);
    if (result) {
      reload();
    }
  };

  const deleteSlot = async () => {
    const result = await es.deleteCompanyEventSlot(event.id, localSlot.id);
    if (result) {
      reload();
    }
  };

  const updateBookedCompany = async (company_id) => {
    if (company_id == 0) {
      company_id = null;
    }
    let confirmed = localSlot.confirmed;
    if (company_id == null) {
      confirmed = false;
    }
    setLocalSlot({
      ...localSlot,
      company_id: company_id,
      confirmed: confirmed,
    });
  };

  return (
    <div key={slot.id}>
      <div className="d-flex align-items-center mb-3">
        <div className="align-self-end w-50px fs-2">#{index + 1}</div>
        <div className="me-3">
          <Label>{container.localeManager.strings.slotName}</Label>
          <Input
            value={localSlot.name}
            onChange={(e) =>
              setLocalSlot({ ...localSlot, name: e.target.value })
            }
          ></Input>
        </div>
        <div className="me-3 w-200px">
          <Label>{container.localeManager.strings.company}</Label>
          <Input
            type="select"
            value={localSlot.company_id}
            onChange={(e) => updateBookedCompany(parseInt(e.target.value))}
          >
            <option value={0}></option>
            {companies.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Input>
        </div>
        <div className="d-flex align-self-end me-3">
          <ButtonGroup size="sm">
            <Button
              onClick={() => setLocalSlot({ ...localSlot, confirmed: true })}
              color={localSlot.confirmed ? "success" : "secondary"}
              disabled={localSlot.company_id == null}
            >
              {container.localeManager.strings.confirmed}
            </Button>
            <Button
              onClick={() => setLocalSlot({ ...localSlot, confirmed: false })}
              color={!localSlot.confirmed ? "warning" : "secondary"}
              disabled={localSlot.company_id == null}
            >
              {container.localeManager.strings.toBeConfirmed}
            </Button>
          </ButtonGroup>
        </div>
        <div className="align-self-end me-3">
          <LoadingButton onClick={saveSlot} size="sm" color="success">
            <FontAwesomeIcon icon="check"></FontAwesomeIcon>
            {container.localeManager.strings.save}
          </LoadingButton>
        </div>
        <div className="align-self-end">
          <LoadingButton onClick={deleteSlot} size="sm" color="danger">
            <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
            {container.localeManager.strings.delete}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
