import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";

export const isTeam = (member) => {
  return member.additional_roles && member.additional_roles.length > 0;
};

export const getZonedTimeFormatted = (utcDate, format = "yyyy-MM-dd HH:mm") => {
  if (!utcDate) {
    return "";
  }
  return formatInTimeZone(
    moment.utc(utcDate).toDate(),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    format
  );
};

export function convertLocalDateToUTCIgnoringTimezone(date) {
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    0,
    0
  );

  return new Date(timestamp);
}

export function convertUTCToLocalDateIgnoringTimezone(utcDate) {
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    0,
    0
  );
}

export function getAddToGoogleCalendarURL(event) {
  const momentDate = event.meetup_date
    ? moment(event.meetup_date)
    : moment(event.start_date);
  const startDate = momentDate.format("YYYYMMDDTHHmmssZ");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const endDate = momentDate.add(3, "hours").format("YYYYMMDDTHHmmssZ");
  const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${startDate}/${endDate}&details=${event.information}&location=${event.start_city}&ctz=${timezone}`;

  return url;
}
