import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { getZonedTimeFormatted } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomBadge from "../../../components/ui/CustomBadge";

export default function EventDetailCard({ container, event }) {
  return (
    <Card>
      <CardBody className="p-0">
        <div
          class="event-hero-image rounded"
          style={{
            height: "100px",
            minHeight: "100px",
            backgroundImage: `url("${event.cover_url}"`,
          }}
        >
          <div style={{ position: "absolute", bottom: "5px", right: "5px" }}>
            <CustomBadge color="success" className="me-3" size="lg">
              <FontAwesomeIcon icon="users" className="me-1"></FontAwesomeIcon>{" "}
              {event.users_attending_count}
            </CustomBadge>
            <CustomBadge color="success" className="me-3">
              <FontAwesomeIcon
                size="lg"
                icon="building"
                className="me-1"
              ></FontAwesomeIcon>{" "}
              {event.companies_attending_count}
            </CustomBadge>
          </div>
        </div>
        <div className="fs-1 text-center h-60px text-success mt-3">
          <Link to={`/events/${event.id}/${event.slug}`}>{event.title}</Link>
        </div>
        <div className="px-3 pb-3 mt-5">
          {event.meetup_date && (
            <div className="mb-3">
              <FontAwesomeIcon icon="calendar" fixedWidth></FontAwesomeIcon>{" "}
              <strong>
                {getZonedTimeFormatted(event.start_date, "PPPP @ p")}
              </strong>
            </div>
          )}
          <div className="mb-3">
            <FontAwesomeIcon icon="server" fixedWidth></FontAwesomeIcon>{" "}
            {container.localeManager.strings[event.mode]}
          </div>
          <div className="mb-3">
            <FontAwesomeIcon icon="gamepad" fixedWidth></FontAwesomeIcon>{" "}
            {event.game.name}
          </div>
          <div className="mb-3">
            <FontAwesomeIcon icon="building" fixedWidth></FontAwesomeIcon>{" "}
            <Link to={`/company/${event.company?.id}`} className="text-warning">
              {event.company?.name}
            </Link>
          </div>
          <div className="fs-3 mt-3 text-center"></div>
        </div>
      </CardBody>
    </Card>
  );
}
