import { useEffect, useState } from "react";
import EnterpriseService from "../../../common/services/enterprise-service";
import { Alert, Col, Row } from "reactstrap";
import EventDetailCard from "./EventDetailCard";
import { Link } from "react-router-dom";

export default function UserUpcomingEvents({ container }) {
  const [events, setEvents] = useState([]);
  const es = new EnterpriseService();

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const result = await es.getUserUpcomingEvents();
    setEvents(result);
  };

  return (
    <Row className="mb-3" noGutters>
      {events && events.length === 0 && (
        <>
          <Alert color="warning">
            <p>{container.localeManager.strings.noUpcomingEvents}</p>
          </Alert>
          <div className="text-center mt-10">
            <Link className="btn btn-primary" to="/events">
              {container.localeManager.strings.browseAllEvents}
            </Link>
          </div>
        </>
      )}
      {events &&
        events.length > 0 &&
        events.map((event) => {
          return (
            <Col sm={12} md={6} lg={4} key={event.id}>
              <EventDetailCard
                event={event}
                container={container}
              ></EventDetailCard>
            </Col>
          );
        })}
    </Row>
  );
}
