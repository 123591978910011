const reactSelectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#1e1e2d',
        borderColor: state.isFocused ? '#A51745' : 'none',
        minHeight: '37px',
        height: '37px',
        boxShadow: 'none',
        border: '1px solid #323248',
        borderRadius: '.475rem',
        '&:hover': {
            borderBottom: '1px solid #323248',
        },
        paddingLeft: '1rem',
        fontSsize: '1.1rem',
        fontWeight: '500'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: (isFocused ? '#323248' : (isSelected ? '#323248' : '#1e1e2d')),
            color: isDisabled ? '#999' : (isSelected || isFocused) ? '#D7D5D9' : '#D7D5D9'
        };
    },
    menuPortal: provided => ({ ...provided, backgroundColor: '#1e1e2d', zIndex: 9999999 }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#1e1e2d',
        zIndex: 9999999,
        border: '1px solid #323248',
        borderRadius: '.475rem',
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent'
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        backgroundColor: '#1e1e2d',
        color: '#D7D5D9'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '27px',
        padding: '0 2px'
    }),
    singleValue: base => ({
        ...base,
        color: "#92929f"
    }),
    placeholder: base => ({
        color: "#92929f"
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
        color: '#92929f',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '27px',
        paddingTop: '7px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#323248',
        color: 'white',
        ':hover': {
            color: '#D7D5D9',
            cursor: 'pointer'
        }
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: 'var(--bs-danger)',
        ':hover': {
            color: '#D7D5D9',
            cursor: 'pointer'
        }
    }),
}

export default reactSelectCustomStyles;