import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import {
  Button,
  ButtonGroup,
  ButtonToggle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import CompanyEventSingleSlot from "./CompanyEventSingleSlot";
import Separator from "../ui/Separator";

export default function CompanyEventSlotsManagement({ container, event }) {
  const [slots, setSlots] = useState([]);
  const [newSlot, setNewSlot] = useState(null);
  const es = new EnterpriseService();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getSlots();
  }, []);

  const getSlots = async () => {
    const result = await es.getCompanyEventSlots(event.id);
    if (result) {
      setSlots(result);

      if (companies.length == 0) {
        // add companies from already booked slots but unique for id
        const companies = result
          .map((slot) => slot.company)
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        setCompanies(companies);
      }
    }
  };

  const addNewSlot = async () => {
    const result = await es.createCompanyEventSlot(event.id, newSlot);
    if (result && result.success) {
      setSlots([...slots, result.slot]);
      setNewSlot(null);
    }
  };

  return (
    <>
      <div>
        <FormGroup row>
          <Col sm={3}>
            <InputGroup>
              <Input
                placeholder="Slot Name"
                value={newSlot?.name}
                onChange={(e) => setNewSlot({ name: e.target.value })}
              ></Input>
              <Button
                size="sm"
                onClick={addNewSlot}
                disabled={!(newSlot?.name && newSlot?.name != "")}
                color="success"
              >
                {container.localeManager.strings.addSlot}
              </Button>
            </InputGroup>
          </Col>
        </FormGroup>
        <div className="my-5">
          <Separator></Separator>
        </div>
        {slots.map((slot, index) => {
          return (
            <CompanyEventSingleSlot
              key={slot.id}
              container={container}
              event={event}
              slot={slot}
              index={index}
              reload={getSlots}
              companies={companies}
            ></CompanyEventSingleSlot>
          );
        })}
      </div>
    </>
  );
}
