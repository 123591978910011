import { useEffect, useState } from "react";
import EnterpriseService from "../../../common/services/enterprise-service";
import moment from "moment";
import { Col, Row } from "reactstrap";
import EventDetailCard from "./EventDetailCard";

export default function TodayEvents({ container, company_id }) {
  const [eventsToday, setEventsToday] = useState([]);
  const es = new EnterpriseService();

  useEffect(() => {
    getEventsToday();
  }, []);

  const getEventsToday = async () => {
    let result = null;

    if (company_id) {
      result = await es.getCompanyEventsCalendar(company_id, {
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().add(24, "hours").format("YYYY-MM-DD"),
        limit: 6,
      });
    } else {
      result = await es.getPublicCompanyEvents({
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().add(24, "hours").format("YYYY-MM-DD"),
        limit: 6,
      });
    }

    if (result && Array.isArray(result)) {
      setEventsToday(result);
    }
  };

  return (
    <>
      {eventsToday.length > 0 && (
        <>
          <div className="mb-3 fs-2 text-center">
            {container.localeManager.strings.eventsNext24Hours}
          </div>
          <Row className="mb-3" noGutters>
            {eventsToday.map((event) => {
              return (
                <Col sm={12} md={6} lg={4} key={event.id}>
                  <EventDetailCard
                    event={event}
                    container={container}
                  ></EventDetailCard>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
}
