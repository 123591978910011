import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import truckyapiClientService from "../../common/services/truckyapi-client-service";
import truckyService from "../../common/services/trucky-service";
import Login from "./Login";
import Cookies from "js-cookie";
import { useSearchParam } from "react-use";

const AuthPage = ({ container }) => {
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
  const navigate = useNavigate();
  const [mustCompleteRegistration, setMustCompleteRegistration] =
    useState(false);

  const loginError = useSearchParam("error");
  const loginStatus = useSearchParam("status");

  useEffect(() => {
    truckyService.setDocumentTitle("Authenticating...");

    const params = new URLSearchParams(window.location.search);

    if (params.has("access_token")) {
      const token = params.get("access_token");
      localStorage.setItem("token", token);

      Cookies.set("token", token, { expires: 365 * 5 });

      getUser(token);
    }
  }, []);

  const getUser = async (token) => {
    await container.dataService.enterpriseService.loadEnterpriseMemberData();

    setGlobalContext({
      member: container.dataService.enterpriseService.member,
      company: container.dataService.enterpriseService.company,
    });

    if (container.dataService.enterpriseService.member.email == null) {
      setMustCompleteRegistration(true);
    } else {
      const result = await truckyapiClientService.getPatreonMember(token);
      container.dataService.data.patreonMember = result.response;

      if (container.dataService.enterpriseService.member != null) {
        container.realTimeService.connectToEcho();
      }

      const params = new URLSearchParams(window.location.search);

      if (params.has("returnUrl") && params.get("returnUrl").includes("http")) {
        window.location.href = params.get("returnUrl");
        return;
      }

      if (params.has("returnUrl")) {
        navigate(params.get("returnUrl"));
        return;
      } else navigate("/");
    }
  };

  return (
    <>
      {loginStatus && loginStatus == "error" && (
        <>
          <div class="d-flex flex-column flex-center flex-column-fluid">
            <div class="d-flex flex-column flex-center text-center p-10">
              <div class="card card-flush w-md-650px py-5">
                <div class="card-body py-15 py-lg-20">
                  <h1 class="fw-bolder text-gray-900">
                    {loginError == "no_account" &&
                      "No account found linked to this Social service"}
                  </h1>

                  <Link to="/login" class="btn btn-primary mt-10 btn-block">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loginStatus == "success" && !mustCompleteRegistration && (
        <div class="d-flex flex-column flex-center flex-column-fluid">
          <div class="d-flex flex-column flex-center text-center p-10">
            <div class="card card-flush w-md-650px py-5">
              <div class="card-body py-15 py-lg-20">
                <h1 class="fw-bolder text-gray-900">
                  {container.localeManager.strings.loading}....
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}
      {mustCompleteRegistration && <Login container={container}></Login>}
    </>
  );
};

export default AuthPage;
