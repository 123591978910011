import { Alert } from "reactstrap";

const ErrorBox = ({ errors }) => {
  return (
    <Alert color="danger">
      {Array.isArray(errors) && (
        <ul className="list-unstyled">
          {Object.keys(errors).map((m) => {
            return <li>{errors[m]}</li>;
          })}
        </ul>
      )}
      {!Array.isArray(errors) && <p>{errors}</p>}
    </Alert>
  );
};

export default ErrorBox;
