import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import EnterpriseService from "../../common/services/enterprise-service";
import { useContext } from "react";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import LazyImage from "./common/LazyImage";

export default function VTCMenu({ container, active }) {
  const { globalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();

  return (
    <div
      className="bg-secondary text-white sidebar rounded p-3 d-none d-lg-block"
      id="sidebar-wrapper"
    >
      <div className="sidebar-heading text-center mb-5">
        <LazyImage
          src={globalContext.member.company.avatar_url}
          className="h-100px rounded"
          placeholder="/assets/company-placeholder.png"
        ></LazyImage>
      </div>
      <div className="fs-5">
        <div className="ps-5 mt-3">
          <Link to="/vtchub" className="menu-item pb-2">
            <span className="menu-icon">
              <FontAwesomeIcon
                className="text-white"
                icon="home"
                fixedWidth
              ></FontAwesomeIcon>
            </span>
            <span
              className={classNames({
                "menu-title": true,
                active: active == "/vtchub",
              })}
            >
              {container.localeManager.strings.dashboard}
            </span>
          </Link>
          <div className="my-5"></div>
          {es.can(globalContext.member, "company.*") && (
            <>
              <Link to="/company/settings" className="menu-item pb-2 ">
                <span className="menu-icon">
                  <FontAwesomeIcon
                    className="text-primary"
                    icon="cog"
                    fixedWidth
                  ></FontAwesomeIcon>
                </span>
                <span
                  className={classNames({
                    "menu-title": true,
                    active: active == "/company/settings",
                  })}
                >
                  {container.localeManager.strings.companySettings}
                </span>
              </Link>
              <div className="my-5"></div>
            </>
          )}
          <Link to="/company/members" className="menu-item pb-2 ">
            <span className="menu-icon">
              <FontAwesomeIcon
                className="text-primary"
                icon="users"
                fixedWidth
              ></FontAwesomeIcon>
            </span>
            <span
              className={classNames({
                "menu-title": true,
                active: active == "/company/members",
              })}
            >
              {container.localeManager.strings.members}
            </span>
          </Link>
          {es.can(globalContext.member, "members.manage_roles") && (
            <Link to="/company/roles" className="menu-item pb-2 ">
              <span className="menu-icon">
                <FontAwesomeIcon
                  className="text-info"
                  icon="users-gear"
                  fixedWidth
                ></FontAwesomeIcon>
              </span>
              <span
                className={classNames({
                  "menu-title": true,
                  active: active == "/company/roles",
                })}
              >
                {container.localeManager.strings.roles}
              </span>
            </Link>
          )}
          {es.can(globalContext.member, "members.manage_roles") && (
            <Link to="/company/tags" className="menu-item pb-2 ">
              <span className="menu-icon">
                <FontAwesomeIcon
                  className="text-warning"
                  icon="user-tag"
                  fixedWidth
                ></FontAwesomeIcon>
              </span>
              <span
                className={classNames({
                  "menu-title": true,
                  active: active == "/company/tags",
                })}
              >
                {container.localeManager.strings.tags}
              </span>
            </Link>
          )}
          {es.can(globalContext.member, "members.manage_roles") && (
            <Link to="/company/ranks" className="menu-item pb-2 ">
              <span className="menu-icon">
                <FontAwesomeIcon
                  className="text-warning"
                  icon="ranking-star"
                  fixedWidth
                ></FontAwesomeIcon>
              </span>
              <span
                className={classNames({
                  "menu-title": true,
                  active: active == "/company/ranks",
                })}
              >
                {container.localeManager.strings.ranks}
              </span>
            </Link>
          )}
          {es.can(globalContext.member, "members.manage_applications") && (
            <Link to="/applications" className="menu-item pb-2 ">
              <span className="menu-icon">
                <FontAwesomeIcon
                  className="text-danger"
                  icon="user-plus"
                  fixedWidth
                ></FontAwesomeIcon>
              </span>
              <span
                className={classNames({
                  "menu-title": true,
                  active: active == "/applications",
                })}
              >
                {container.localeManager.strings.applications}
              </span>
            </Link>
          )}
          <div className="my-5"></div>
          <Link to="/jobs" className="menu-item pb-2 ">
            <span className="menu-icon">
              <FontAwesomeIcon
                className="text-info"
                icon="suitcase"
              ></FontAwesomeIcon>
            </span>
            <span
              className={classNames({
                "menu-title": true,
                active: active == "/jobs",
              })}
            >
              {container.localeManager.strings.jobs}
            </span>
          </Link>
          <div className="my-5"></div>
          {globalContext.company != null &&
            (globalContext.company.company_type == "realistic" ||
              globalContext.company.company_type == "both") && (
              <>
                <Link to="/balance" className="menu-item pb-2 ">
                  <span className="menu-icon">
                    <FontAwesomeIcon
                      className="text-success"
                      icon="piggy-bank"
                    ></FontAwesomeIcon>
                  </span>
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/balance",
                    })}
                  >
                    {container.localeManager.strings.balance}
                  </span>
                </Link>
                <Link to="/garages" className="menu-item pb-2 ">
                  <span className="menu-icon">
                    <FontAwesomeIcon icon="warehouse"></FontAwesomeIcon>
                  </span>
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/garages",
                    })}
                  >
                    {container.localeManager.strings.garages}
                  </span>
                </Link>
                <Link to="/vehicles" className="menu-item pb-2 ">
                  <span className="menu-icon">
                    <FontAwesomeIcon
                      className="text-warning"
                      icon="truck"
                    ></FontAwesomeIcon>
                  </span>
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/vehicles",
                    })}
                  >
                    {container.localeManager.strings.fleet}
                  </span>
                </Link>
                <Link to="/maintenances" className="menu-item pb-2 ">
                  <span className="menu-icon">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon="wrench"
                    ></FontAwesomeIcon>
                  </span>
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/maintenances",
                    })}
                  >
                    {container.localeManager.strings.maintenance}
                  </span>
                </Link>
              </>
            )}
          <div className="my-5"></div>
          <Link to="/stats" className="menu-item pb-2 ">
            <span className="menu-icon">
              <FontAwesomeIcon
                className="text-danger"
                icon="table"
              ></FontAwesomeIcon>
            </span>
            <span
              className={classNames({
                "menu-title": true,
                active: active == "/stats",
              })}
            >
              {container.localeManager.strings.stats}
            </span>
          </Link>
          <div className="my-5"></div>
          <Link to="/company/events/calendar" className="menu-item pb-2 ">
            <span className="menu-icon">
              <FontAwesomeIcon
                className="text-info"
                icon="calendar"
              ></FontAwesomeIcon>
            </span>
            <span
              className={classNames({
                "menu-title": true,
                active: active == "/company/events/calendar",
              })}
            >
              {container.localeManager.strings.calendar}
            </span>
          </Link>
          {es.can(globalContext.member, "events.manage") && (
            <>
              <Link to="/company/events" className="menu-item pb-2 ">
                <span className="menu-icon">
                  <FontAwesomeIcon
                    className="text-info"
                    icon="calendar"
                  ></FontAwesomeIcon>
                </span>
                <span
                  className={classNames({
                    "menu-title": true,
                    active: active == "/company/events",
                  })}
                >
                  {container.localeManager.strings.events}
                </span>
              </Link>
              <div className="my-5"></div>
            </>
          )}
          <div className="my-5"></div>
          {es.can(globalContext.member, "feeds.manage") && (
            <>
              <Link to="/feeds/manage" className="menu-item pb-2 ">
                <span className="menu-icon">
                  <FontAwesomeIcon
                    className="text-info"
                    icon="newspaper"
                  ></FontAwesomeIcon>
                </span>
                <span
                  className={classNames({
                    "menu-title": true,
                    active: active == "/feeds/manage",
                  })}
                >
                  {container.localeManager.strings.newsFeed}
                </span>
              </Link>
              <div className="my-5"></div>
            </>
          )}
          {es.can(globalContext.member, "achievements.manage") && (
            <Link to="/company/achievements" className="menu-item pb-2 ">
              <span className="menu-icon">
                <FontAwesomeIcon
                  className="text-warning"
                  icon="trophy"
                ></FontAwesomeIcon>
              </span>
              <span
                className={classNames({
                  "menu-title": true,
                  active: active == "/company/achievements",
                })}
              >
                {container.localeManager.strings.achievements}
              </span>
            </Link>
          )}
          <div className="my-5"></div>
          {globalContext.company != null &&
            (globalContext.company.company_type == "realistic" ||
              globalContext.company.company_type == "both") && (
              <>
                <span className="menu-item pb-2 ">
                  <span className="menu-icon">
                    <FontAwesomeIcon
                      className="text-success"
                      icon="store"
                    ></FontAwesomeIcon>
                  </span>
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/vtchub",
                    })}
                  >
                    {container.localeManager.strings.globalMarket}
                  </span>
                </span>
                <Link to="/market/1" className="menu-item pb-2 fs-6 ">
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/market/1",
                    })}
                  >
                    {container.localeManager.strings.europe}
                  </span>
                </Link>
                <Link to="/market/2" className="menu-item pb-2 fs-6">
                  <span
                    className={classNames({
                      "menu-title": true,
                      active: active == "/market/2",
                    })}
                  >
                    {container.localeManager.strings.usa}
                  </span>
                </Link>
              </>
            )}
        </div>
      </div>
    </div>
  );
}
