import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import EventDetailCard from "../../web/components/events/EventDetailCard";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import moment from "moment";

export default function UpcomingCompanyEvents({
  container,
  company_id,
  onlyPrivate = true,
  limit,
}) {
  const [events, setEvents] = useState([]);
  const es = new EnterpriseService();

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    let result = null;

    const dateFrom = moment().startOf("day").format("YYYY-MM-DD");

    if (onlyPrivate) {
      result = await es.getCompanyEventsCalendar(company_id, {
        date_from: dateFrom,
        limit: limit,
      });
    } else {
      result = await es.getPublicCompanyEvents({
        date_from: dateFrom,
        company_id: company_id,
        limit: limit,
      });
    }
    if (result) {
      setEvents(result);
    }
  };

  return (
    <>
      {events.length > 0 && (
        <Card className="border mt-3">
          <CardHeader>{container.localeManager.strings.upcomingCompanyEvents}</CardHeader>
          <CardBody>
            <Row noGutters>
              {events.map((event, index) => {
                return (
                  <Col sm={12} md={6} lg={4} key={event.id}>
                    <EventDetailCard
                      key={index}
                      event={event}
                      container={container}
                    />
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
}
