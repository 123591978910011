import { useContext, useEffect } from "react";
import { EventsCalendar } from "../../web/components/events/EventsCalendar";
import { EnterpriseContext } from "./EnterpriseContext";
import { Card, CardBody, Col, Row } from "reactstrap";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import truckyService from "../../common/services/trucky-service";

export default function CompanyEventsCalendar({ container }) {
  const { globalContext } = useContext(EnterpriseContext);

  useEffect(() => {
     truckyService.setDocumentTitle('Company Events Calendar');
  }, []);
  
  return (
    <Row noGutters>
      <Col sm="auto">
        <VTCMenu
          active={"/company/events/calendar"}
          container={container}
        ></VTCMenu>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <Breadcrumbs
              breadcrumbs={[
                {
                  to: "/vtchub",
                  title: container.localeManager.strings.vtcHub,
                },
                {
                  title: container.localeManager.strings.companyEvents,
                },
                {
                  title: container.localeManager.strings.calendar,
                },
              ]}
            ></Breadcrumbs>
            <h5 className="d-flex w-100 mb-5 justify-content-between align-items-center">
              <div>{container.localeManager.strings.companyEvents}</div>
            </h5>
            <div>
              <EventsCalendar
                container={container}
                company_id={globalContext.company.id}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
