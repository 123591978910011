import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import VTCMenu from "../../web/components/VTCMenu";
import LoadingButton from "../ui/LoadingButton";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router";
import Paginator from "../ui/Paginator";
import truckyService from "../../common/services/trucky-service";
import NotEnoughPermissions from "./NotEnoughPermissions";

export default function CompanyEvents({ container }) {
  const [events, setEvents] = useState([]);
  const { globalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showImportEventDialog, toggleImportEventDialog] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [TMPEvents, setTMPEvents] = useState([]);

  useEffect(() => {
    truckyService.setDocumentTitle("Company Events");

    if (es.can(globalContext.member, "events.manage")) {
      getEvents();
    }
  }, [pagination.page, pagination.perPage]);

  const getEvents = async () => {
    setLoading(true);
    const result = await es.getCompanyEventsForManagement(
      globalContext.company.id,
      {
        ...pagination,
      }
    );
    if (result && result.data) {
      setEvents(result.data);
      setTotalPages(result.last_page);
    }
    setLoading(false);
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  const deleteEvent = async (event) => {
    if (
      await confirmWrapper(container.localeManager.strings.areYouSure, {
        container: container,
      })
    ) {
      setLoading(true);
      const result = await es.deleteCompanyEvent(event.id);
      if (result.success) {
        getEvents();
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const syncAllTruckersMPEvents = async () => {
    setLoading(true);
    const result = await es.syncAllTruckersMPEvents();
    if (result.success) {
      getEvents();
    } else {
      toast.error(result.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showImportEventDialog) {
      getTMPEvents();
    }
  }, [showImportEventDialog]);

  const getTMPEvents = async () => {
    const result = await es.getTruckersMPCompanyEvents();
    if (result?.success) {
      setTMPEvents(result.events);
    } else {
      toast.error(result.message);
    }
  };

  const importTMPEvent = async () => {
    const TMPEvents = document.getElementById("TMPEvents");
    const TMPEventId = TMPEvents.value;
    if (TMPEventId) {
      const result = await es.importTruckersMPEvent(TMPEventId);
      if (result.success) {
        toggleImportEventDialog(false);
        navigate(`/company/events/manage/edit/${result.event.id}`);
      } else {
        toast.error(result.message);
      }
    } else {
      toast.error("Please select a TruckersMP Event");
    }
  };

  if (!es.can(globalContext.member, "events.manage")) {
    return <NotEnoughPermissions container={container} />;
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters>
        <Col sm="auto">
          <VTCMenu active={"/company/events"} container={container}></VTCMenu>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <Breadcrumbs
                breadcrumbs={[
                  {
                    to: "/vtchub",
                    title: container.localeManager.strings.vtcHub,
                  },
                  {
                    to: "/company/events",
                    title: container.localeManager.strings.events,
                  },
                  {
                    title: container.localeManager.strings.companyEvents,
                  },
                ]}
              ></Breadcrumbs>
              <h5 className="d-flex w-100 mb-5 justify-content-between align-items-center">
                <div>{container.localeManager.strings.companyEvents}</div>
                <div>
                  {globalContext.company.external_id != null && (
                    <>
                      <Button
                        color="primary"
                        className="me-3"
                        size="sm"
                        onClick={() => toggleImportEventDialog(true)}
                      >
                        Import Events from TruckersMP
                      </Button>
                    </>
                  )}
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => navigate("/company/events/manage/create")}
                  >
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                    {container.localeManager.strings.createEvent}
                  </Button>
                </div>
              </h5>
              <Paginator
                rowsPerPageSelected={pagination.perPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                showRowsPerPage={true}
                forcePage={pagination.page - 1}
                totalPages={totalPages}
                handlePageClick={handlePageClick}
              />
              <Table
                responsive
                striped
                size="sm"
                className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3"
                hover
              >
                <thead>
                  <tr className="fw-bolder text-gray-700 bg-light">
                    <th>ID</th>
                    <th>{container.localeManager.strings.title}</th>
                    <th scope="col" className="ps-4">
                      {container.localeManager.strings.author}
                    </th>

                    <th>{container.localeManager.strings.startDateUTC}</th>
                    <th>{container.localeManager.strings.startCity}</th>
                    <th>{container.localeManager.strings.endCity}</th>
                    <th>{container.localeManager.strings.server}</th>
                    <th>{container.localeManager.strings.status}</th>
                    <th>{container.localeManager.strings.private}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((m) => {
                    return (
                      <tr>
                        <td>{m.id}</td>
                        <td>{m.title}</td>
                        <td>{m.author?.name}</td>
                        <td>
                          {moment(m.start_date).format("YYYY-MM-DD H:mm")}
                        </td>
                        <td>{m.start_city}</td>
                        <td>{m.end_city}</td>
                        <td>{m.server}</td>
                        <td>{m.status}</td>
                        <td>
                          {m.private ? (
                            <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <Button
                            onClick={() =>
                              navigate(`/company/events/manage/edit/${m.id}`)
                            }
                            className="btn-icon w-30px h-30px ms-auto"
                            size="sm"
                            color="active-light-primary"
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              className="me-0"
                            />
                          </Button>
                          <Button
                            onClick={() => deleteEvent(m)}
                            className="btn-icon w-30px h-30px ms-auto"
                            size="sm"
                            color="active-light-danger"
                          >
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="me-0"
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Paginator
                rowsPerPageSelected={pagination.perPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                showRowsPerPage={true}
                forcePage={pagination.page - 1}
                totalPages={totalPages}
                handlePageClick={handlePageClick}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showImportEventDialog && (
        <Modal
          isOpen={showImportEventDialog}
          toggle={() => toggleImportEventDialog(false)}
        >
          <ModalHeader>
            {container.localeManager.strings.importEventFromTruckersMP}
          </ModalHeader>
          <ModalBody>
            <div className="my-3">
              <Button
                block
                color="primary"
                className="me-3"
                size="sm"
                onClick={syncAllTruckersMPEvents}
              >
                {container.localeManager.strings.syncAllTruckersMPEvents}
              </Button>
            </div>
            <FormGroup>
              <Label>{container.localeManager.strings.truckersMPEvent}</Label>
              <Input id="TMPEvents" type="select">
                <option value="">
                  {container.localeManager.strings.selectOne}
                </option>
                {TMPEvents.map((e) => {
                  return (
                    <option value={e.id}>
                      {e.title} - {moment(e.start_date).format("YYYY-MM-DD")} (
                      {e.id})
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <LoadingButton color="primary" size="sm" onClick={importTMPEvent}>
                {container.localeManager.strings.import}
              </LoadingButton>
            </FormGroup>
          </ModalBody>
        </Modal>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </LoadingOverlay>
  );
}
