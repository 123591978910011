import tipsList from "../common/constants/tipsList";
import Tips from "./Tips";
import React, { useState, useEffect } from "react";
import truckyApiClientService from "../common/services/truckyapi-client-service";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import CustomBadge from "./ui/CustomBadge";
import { useNavigate } from "react-router";
import EnterpriseService from "../common/services/enterprise-service";
import Paginator from "./ui/Paginator";
import { Link } from "react-router-dom";

import BoxDynamicAd from "../ads/BoxDynamicAd";
import truckyService from "../common/services/trucky-service";
import UserLeaderboardsNavigator from "./UserLeaderboardsNavigator";
import Rating from "./Rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LazyImage from "../web/components/common/LazyImage";

const HardcoreLeaderboards = ({ container }) => {
  const navigate = useNavigate();
  const es = new EnterpriseService();
  const [filters, setFilters] = useState({
    game: "",
    name: "",
    country: "",
    month: moment().month(),
    year: moment().year(),
  });
  const [loading, setLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 50 });
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    truckyService.setDocumentTitle("User Leaderboards");
  }, []);

  useEffect(() => {
    getLeaderboardData();
  }, [filters.year, filters.month]);

  useEffect(() => {
    getLeaderboardData();
  }, [pagination, filters.period]);

  const search = async () => {
    setPagination({ ...pagination, page: 1 });
  };

  const getLeaderboardData = async () => {
    setLoading(true);
    let adjFilters = { ...filters, month: filters.month + 1 };

    const result = await es.getUsersHardcoreLeaderboards(
      adjFilters,
      pagination
    );
    setLeaderboardData(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const nextPeriod = () => {
    var newDate = moment()
      .year(filters.year)
      .month(filters.month)
      .add(1, "month");

    setFilters({ ...filters, month: newDate.month(), year: newDate.year() });
  };

  const prevPeriod = () => {
    var newDate = moment()
      .year(filters.year)
      .month(filters.month)
      .subtract(1, "month");

    setFilters({ ...filters, month: newDate.month(), year: newDate.year() });
  };

  const isPrevDisabled = () => {
    return false;
  };

  const isNextDisabled = () => {
    var newDate = moment()
      .year(filters.year)
      .month(filters.month)
      .add(1, "month");
    var endDate = moment().add(1, "month");

    if (newDate.month() == endDate.month() && newDate.year() == endDate.year())
      return true;

    return false;
  };

  const getPeriodDescription = () => {
    return (
      <>
        {container.localeManager
          .moment()
          .year(filters.year)
          .month(filters.month)
          .format("MMMM")}{" "}
        {filters.year}
      </>
    );
  };

  const getDeliveryRatingStars = (rating) => {
    let color = "";

    if (rating >= 4) color = "text-success";

    if (rating < 4) color = "text-warning";

    if (rating < 3) color = "text-danger";

    let stars = [];

    for (let index = 1; index <= 5; index++) {
      let icon = "star";

      if (index <= rating) icon = "star";
      else if (index - rating <= 0.5) icon = "star-half-stroke";
      else icon = ["far", "star"];

      stars.push(
        <FontAwesomeIcon icon={icon} className={color}></FontAwesomeIcon>
      );
    }

    return stars;
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters>
        <Col>
          <Card>
            <CardBody>
              <UserLeaderboardsNavigator
                container={container}
                active="hardcore"
              ></UserLeaderboardsNavigator>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label>{container.localeManager.strings.username}</Label>
                    <Input
                      placeholder={
                        container.localeManager.strings.searchForPlayerName
                      }
                      value={filters.name}
                      type="text"
                      onChange={(e) =>
                        setFilters({ ...filters, name: e.target.value })
                      }
                    ></Input>
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label>{container.localeManager.strings.country}</Label>
                    <Input
                      placeholder={
                        container.localeManager.strings.country2DigitsCode
                      }
                      value={filters.country}
                      type="text"
                      onChange={(e) =>
                        setFilters({ ...filters, country: e.target.value })
                      }
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <Button size="sm" color="primary" onClick={() => search()}>
                    {container.localeManager.strings.searchButton}
                  </Button>
                </Col>
              </Row>
              {leaderboardData != null && leaderboardData.length > 0 && (
                <>
                  <Paginator
                    rowsPerPageSelected={pagination.perPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    showRowsPerPage={true}
                    forcePage={pagination.page - 1}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                  >
                    <Button
                      disabled={isPrevDisabled()}
                      className="btn-icon w-30px h-30px me-3"
                      color="active-light-primary"
                      onClick={() => prevPeriod()}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        className="me-0"
                        icon="chevron-left"
                      ></FontAwesomeIcon>
                    </Button>
                    {getPeriodDescription()}
                    <Button
                      disabled={isNextDisabled()}
                      className="btn-icon w-30px h-30px ms-3"
                      color="active-light-primary"
                      onClick={() => nextPeriod()}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        className="me-0"
                        icon="chevron-right"
                      ></FontAwesomeIcon>
                    </Button>
                  </Paginator>
                  <Table
                    responsive
                    striped
                    size="sm"
                    className="table-row-dashed table-row-gray-300 align-middle gs-0 leaderboards mt-3"
                    hover
                  >
                    <thead>
                      <tr className="fw-bolder text-gray-700 bg-light">
                        <th className="text-center">#</th>
                        <th className="text-start">
                          {container.localeManager.strings.driver}
                        </th>
                        <th className="text-start">
                          {container.localeManager.strings.company}
                        </th>
                        <th className="text-center">
                          {container.localeManager.strings.country}
                        </th>
                        <th className="text-center">
                          {container.localeManager.strings.points}
                        </th>
                        <th className="text-center">
                          {container.localeManager.strings.rating}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaderboardData.map((m) => {
                        return (
                          <tr>
                            <td class="text-center">
                              <span className="ranking">{m.position}</span>
                            </td>
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    className="avatar h-50px min-w-50px rounded me-3"
                                    src={m.avatar_url}
                                  />
                                </div>
                                <div>
                                  <Link to={`/user/${m.user_id}`}>
                                    {m.name}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              {m.company != null && (
                                <div class="d-flex align-items-center">
                                  <div>
                                    <LazyImage
                                      className="avatar h-50px min-w-50px rounded me-3"
                                      src={m.company.avatar_url}
                                      placeholder="/assets/company-placeholder.png"
                                    ></LazyImage>
                                  </div>
                                  <div>
                                    <Link to={`/company/${m.company.id}`}>
                                      {m.company.name}
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              {m.country != undefined && m.country != "" && (
                                <img
                                  src={
                                    "https://flagcdn.com/w40/" +
                                    m.country.toLowerCase() +
                                    ".png"
                                  }
                                />
                              )}
                            </td>
                            <td className="text-center">
                              <span className="points">
                                {container.localeManager
                                  .numeral(m.points)
                                  .format(0, 0)}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="points">
                                {getDeliveryRatingStars(m.avg_rating)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Paginator
                    rowsPerPageSelected={pagination.perPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    showRowsPerPage={true}
                    forcePage={pagination.page - 1}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        {!container.dataService.data.enablePremiumFeature() && (
          <div className="ad-column-placeholder">
            <Tips
              tipKey={tipsList.KEYS.LEADERBOARDS}
              localeManager={container.localeManager}
            ></Tips>
            {!window.overwolf && (
              <BoxDynamicAd container={container} className="my-3" />
            )}
          </div>
        )}
      </Row>
    </LoadingOverlay>
  );
};

export default HardcoreLeaderboards;
