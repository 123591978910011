import { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";

import { confirmWrapper } from "../ui/askConfirm";
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate } from "react-router";
import Paginator from "../ui/Paginator";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import OrderByButtons from "../ui/OrderByButtons";
import truckyService from "../../common/services/trucky-service";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import Select from "react-select";

import CustomBadge from "../ui/CustomBadge";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { Link } from "react-router-dom";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import VTCMenu from "../../web/components/VTCMenu";
import config from "../../config";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import EditMemberProperties from "./EditMemberProperties";

const classnames = require("classnames");
require("linqjs");

const availableColumns = [
  "id",
  "member",
  "role",
  "revenue",
  "distance",
  "mass",
  "steam",
  "points",
  "level",
  "last_job_days",
  "tags",
  "total_earned",
  "roleSalary",
  "rank",
];

const defaultColumns = ["id", "member", "role", "revenue", "distance", "mass"];

const Members = ({ eventBus, container }) => {
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const [loading, setLoading] = useState({
    members: false,
    roles: false,
    vehicles: false,
    jobs: false,
    permissions: false,
  });
  const es = new EnterpriseService();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const [searchParameters, setSearchParameters] = useState(
    truckyService.loadSearchParameters("enteprise_members", {
      name: "",
      role_id: "",
      sortingField: "roles.order",
      sortingDirection: "ASC",
      inactive: false,
      steam_id: "",
      steam_username: "",
      withSteam: true,
    })
  );
  const [userChangeRole, setUserChangeRole] = useState(null);
  const [showChangeRoleModal, toggleChangeRoleModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [columns, setColumns] = useState(
    truckyService.loadSearchParameters("members_table", defaultColumns)
  );

  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("Members");
  }, []);

  useEffect(() => {
    truckyService.saveSearchParameters("enteprise_members", searchParameters);
  }, [searchParameters]);

  useEffect(() => {
    getTags();
    getRoles();
  }, []);

  useEffect(() => {
    getMembers();
  }, [
    pagination,
    searchParameters.sortingField,
    searchParameters.sortingDirection,
  ]);

  useEffect(() => {
    toggleChangeRoleModal(userChangeRole != null);
  }, [userChangeRole]);

  useEffect(() => {
    truckyService.saveSearchParameters("members_table", columns);
  }, [columns.length]);

  const getMembers = async () => {
    setLoading(true);
    const result = await es.getMembers(
      company,
      pagination,
      searchParameters,
      "role"
    );
    if (result != null) {
      setMembers(result.data);
      setTotalPages(result.last_page);
    }
    setLoading(false);
  };

  const exportMembers = async () => {
    setLoading(true);
    await es.exportMembers(company);
    setLoading(false);
  };

  const getColoredSpan = (label, color) => {
    if (color != null) {
      return <span style={{ color: color }}>{label}</span>;
    } else {
      return <span>{label}</span>;
    }
  };

  const removeMember = async (user) => {
    if (
      await confirmWrapper(container.localeManager.strings.kickMemberConf, {
        container: container,
      })
    ) {
      const result = await es.kickMember(company, user);
      if (result.success) {
        getMembers();
      } else {
        toast.error(result.message);
      }
    }
  };

  const canBeKicked = (member) => {
    if (member.role != null && member.role.owner) return false;

    return true;
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const changeSortingField = ({ field, direction }) => {
    setSearchParameters({
      ...searchParameters,
      sortingField: field,
      sortingDirection: direction,
    });
  };

  const syncMembers = async () => {
    setLoading(true);
    const result = await es.syncMembers(globalContext.company);
    if (result.success) {
      toast.success(
        container.localeManager.formatString(
          container.localeManager.strings.synchedXMembers,
          [result.newMembers]
        ),
        { position: "top-right" }
      );
    }
    if (result.newMembers > 0) {
      await getMembers();
    }
    setLoading(false);
  };

  const getContextualMenuItems = (user) => {
    const items = [];

    let canChangeRole = true;

    if (user.role != null && user.role.owner) canChangeRole = false;

    items.push({
      title: container.localeManager.strings.details,
      click: () => navigate(`/user/${user.id}`),
      icon: <FontAwesomeIcon icon="eye"></FontAwesomeIcon>,
    });

    if (es.can(member, "members.manage_roles")) {
      items.push({
        title: container.localeManager.strings.roleAndTags,
        click: () => setUserChangeRole(user),
        icon: <FontAwesomeIcon icon="user-shield"></FontAwesomeIcon>,
      });
    }

    if (es.can(member, "members.kick") && canBeKicked(user)) {
      items.push({
        title: container.localeManager.strings.kickMember,
        click: () => removeMember(user),
        icon: <FontAwesomeIcon icon="user-slash"></FontAwesomeIcon>,
      });
    }

    return items;
  };

  const getTags = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyTags(company.id);

      if (!result.message) setTags(result);
    }
  };

  const getRoles = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRoles(company);
      if (!result.message) setRoles(result);
    }
  };

  const toggleColumn = (checked, field) => {
    if (checked) {
      setColumns([...columns, field]);
    } else {
      setColumns(columns.filter((m) => m != field));
    }
  };

  const showSalary = () => {
    if (company.company_type == "realistic" || company.company_type == "both") {
      if (
        globalContext.member &&
        globalContext.member.company_id == company.id &&
        (es.can(globalContext.member, "company.*") ||
          es.can(globalContext.member, "members.*"))
      )
        return true;
    }

    return false;
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/company/members"} container={container}></VTCMenu>

        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        to: "/members",
                        title: container.localeManager.strings.members,
                      },
                    ]}
                  ></Breadcrumbs>
                  <h5 className="d-inline-block w-100 mb-3">
                    {container.localeManager.strings.members}
                    <div className="float-end mt-3 mt-md-0">
                      {es.can(member, "members.manage_roles") && (
                        <Link
                          className="btn btn-sm btn-primary"
                          to="/company/roles"
                        >
                          <FontAwesomeIcon icon="users-gear"></FontAwesomeIcon>
                          {container.localeManager.strings.manageRoles}
                        </Link>
                      )}
                      {es.can(member, "members.manage_roles") && (
                        <Link
                          className="btn btn-sm btn-primary ms-md-2"
                          to="/company/tags"
                        >
                          <FontAwesomeIcon icon="user-tag"></FontAwesomeIcon>
                          {container.localeManager.strings.tags}
                        </Link>
                      )}
                      {es.can(member, "members.manage_applications") && (
                        <Button
                          size="sm"
                          className="ms-0 ms-md-2 mt-3 mt-md-0"
                          color="primary"
                          onClick={() => navigate("/applications")}
                        >
                          <FontAwesomeIcon icon="tasks"></FontAwesomeIcon>
                          {container.localeManager.strings.manageApplications}
                        </Button>
                      )}
                    </div>
                  </h5>
                  <FormGroup row>
                    <Col>
                      <Label>{container.localeManager.strings.driver}</Label>
                      <Input
                        type="text"
                        minLength={3}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            name: e.target.value,
                          })
                        }
                        value={searchParameters.name}
                      ></Input>
                    </Col>
                    <Col>
                      <Label>
                        {container.localeManager.strings.roleNoPoints}
                      </Label>
                      <Input
                        type="select"
                        value={searchParameters.role_id}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            role_id: e.target.value,
                          })
                        }
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        {roles.map((m) => {
                          return <option value={m.id}>{m.name}</option>;
                        })}
                      </Input>
                    </Col>
                    <Col
                      sm="auto"
                      className="d-flex align-items-end mt-3 mt-md-0"
                    >
                      <Button size="sm" onClick={getMembers} color="primary">
                        <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                        {container.localeManager.strings.search}
                      </Button>
                      <Button size="sm" className="ms-2" onClick={exportMembers} color="primary">
                        <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                        {container.localeManager.strings.export}
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4}>
                      <Label>
                        {container.localeManager.strings.searchBySteamID}
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            steam_id: e.target.value,
                          })
                        }
                        value={searchParameters.steam_id}
                      ></Input>
                    </Col>
                    <Col sm={4}>
                      <Label>
                        {container.localeManager.strings.searchBySteamUsername}
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            steam_username: e.target.value,
                          })
                        }
                        value={searchParameters.steam_username}
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4}>
                      <label class="form-check form-switch form-check-custom form-check-solid">
                        <input
                          onChange={(e) =>
                            setSearchParameters({
                              ...searchParameters,
                              inactive: e.target.checked,
                            })
                          }
                          class="form-check-input"
                          type="checkbox"
                          checked={searchParameters.inactive}
                        />
                        <span class="form-check-label">
                          {container.localeManager.strings.inactive}
                        </span>
                      </label>
                    </Col>
                  </FormGroup>
                  {!loading && (
                    <>
                      {totalPages > 1 && (
                        <div className="mb-3">
                          <Paginator
                            showRowsPerPage={true}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            forcePage={pagination.page - 1}
                            totalPages={totalPages}
                            handlePageClick={handlePageClick}
                          />
                        </div>
                      )}
                      <Table
                        responsive
                        striped
                        size="sm"
                        className="table-row-dashed table-row-gray-300 align-middle gs-0"
                        hover
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-700 bg-light">
                            {columns.includes("id") && (
                              <th scope="col" className="ps-4">
                                #
                              </th>
                            )}
                            {columns.includes("member") && (
                              <th scope="col">
                                {container.localeManager.strings.name}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="name"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("steam") && (
                              <th scope="col">Steam</th>
                            )}
                            {columns.includes("role") && (
                              <th scope="col">
                                {container.localeManager.strings.roleNoPoints}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="roles.order"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("revenue") && (
                              <th scope="col">
                                {container.localeManager.strings.revenue}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_revenue"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("distance") && (
                              <th scope="col">
                                {container.localeManager.strings.totDistance}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_driven_distance_km"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("mass") && (
                              <th scope="col">
                                {container.localeManager.strings.totMass}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_cargo_mass"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("points") && (
                              <th scope="col">
                                {container.localeManager.strings.points}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="points"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("level") && (
                              <th scope="col">
                                {container.localeManager.strings.level}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="level"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("last_job_days") && (
                              <th scope="col">
                                {container.localeManager.strings.last_job_days}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="last_job_days"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {showSalary() &&
                              columns.includes("total_earned") && (
                                <th scope="col">
                                  {container.localeManager.strings.total_earned}
                                  <OrderByButtons
                                    container={container}
                                    currentSortingDirection={
                                      searchParameters.sortingDirection
                                    }
                                    currentSortingField={
                                      searchParameters.sortingField
                                    }
                                    field="total_earned"
                                    onClick={changeSortingField}
                                  ></OrderByButtons>
                                </th>
                              )}
                            {columns.includes("roleSalary") && (
                              <th scope="col">
                                {container.localeManager.strings.roleSalary}
                              </th>
                            )}
                            {columns.includes("tags") && (
                              <th scope="col">
                                {container.localeManager.strings.tags}
                              </th>
                            )}
                            {columns.includes("rank") && (
                              <th scope="col">
                                {container.localeManager.strings.rank}
                              </th>
                            )}
                            <th scope="col" className="text-end pe-3">
                              <ButtonDropdownMenu
                                buttonContent={
                                  <FontAwesomeIcon
                                    icon="table-columns"
                                    className="m-0"
                                  ></FontAwesomeIcon>
                                }
                                menuTitle={
                                  container.localeManager.strings.columns
                                }
                                menuId="jobs_table_columns_selector"
                                menuContent={
                                  <div className="p-3">
                                    {availableColumns.map((m) => {
                                      return (
                                        <div className="mt-2">
                                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                                            <input
                                              checked={columns.includes(m)}
                                              class="form-check-input me-1"
                                              type="checkbox"
                                              onChange={(e) =>
                                                toggleColumn(
                                                  e.target.checked,
                                                  m
                                                )
                                              }
                                            />{" "}
                                            {container.localeManager.strings[m]}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                }
                                buttonClassNames="btn-text p-0"
                                buttonColor="transparent"
                                title="Columns"
                              ></ButtonDropdownMenu>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {members.map((m) => {
                            return (
                              <tr>
                                {columns.includes("id") && (
                                  <td className="ps-1">{m.id}</td>
                                )}
                                {columns.includes("member") && (
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Link to={`/user/${m.id}`}>
                                        <img
                                          src={m.avatar_url}
                                          className="avatar h-30px rounded me-3"
                                        />
                                        {getColoredSpan(m.name, m.role?.color)}
                                      </Link>
                                    </div>
                                  </td>
                                )}
                                {columns.includes("steam") && (
                                  <td>
                                    {m.steam != null && (
                                      <a
                                        onClick={() =>
                                          truckyService.openUrlInDefaultBrowser(
                                            `https://steamcommunity.com/profiles/${m.steam.external_user_id}`
                                          )
                                        }
                                      >
                                        {m.steam.external_username}
                                      </a>
                                    )}
                                  </td>
                                )}
                                {columns.includes("role") && (
                                  <td>
                                    {m.role != null
                                      ? getColoredSpan(
                                          m.role.name,
                                          m.role.color
                                        )
                                      : ""}
                                  </td>
                                )}
                                {columns.includes("revenue") && (
                                  <td>
                                    {m.total_revenue != null
                                      ? container.localeManager
                                          .numeral(m.total_revenue)
                                          .format("0,0")
                                      : 0}{" "}
                                    {company.currency}
                                  </td>
                                )}
                                {columns.includes("distance") && (
                                  <td>
                                    {m.total_driven_distance_km != null
                                      ? UserDefinedUnits.convertDistance(
                                          member,
                                          null,
                                          m.total_driven_distance_km
                                        )
                                      : 0}
                                  </td>
                                )}
                                {columns.includes("mass") && (
                                  <td>
                                    {m.total_cargo_mass_t != null
                                      ? UserDefinedUnits.convertWeight(
                                          member,
                                          null,
                                          m.total_cargo_mass_t
                                        )
                                      : 0}
                                  </td>
                                )}
                                {columns.includes("points") && (
                                  <td>
                                    {container.localeManager
                                      .numeral(m.points)
                                      .format()}
                                  </td>
                                )}
                                {columns.includes("level") && (
                                  <td>
                                    {container.localeManager
                                      .numeral(m.level)
                                      .format()}
                                  </td>
                                )}
                                {columns.includes("last_job_days") && (
                                  <td>
                                    {m.last_job_days != null && (
                                      <span>
                                        {container.localeManager
                                          .numeral(m.last_job_days)
                                          .format()}{" "}
                                        {container.localeManager.strings.days}
                                      </span>
                                    )}
                                  </td>
                                )}
                                {showSalary() &&
                                  columns.includes("total_earned") && (
                                    <td>
                                      {container.localeManager
                                        .numeral(m.total_earned)
                                        .format()}{" "}
                                      T¢
                                    </td>
                                  )}
                                {columns.includes("roleSalary") && (
                                  <td>
                                    {m.role?.additional_member_salary != null &&
                                      m.role?.additional_member_salary > 0 && (
                                        <span>
                                          {m.role?.additional_member_salary}
                                        </span>
                                      )}
                                  </td>
                                )}
                                {columns.includes("tags") && (
                                  <td>
                                    {m.company_tags != null &&
                                      m.company_tags.length > 0 && (
                                        <>
                                          {m.company_tags.map((m) => {
                                            return (
                                              <CustomBadge
                                                light={true}
                                                size="sm"
                                                className="me-3 mb-2"
                                                style={
                                                  m.color
                                                    ? {
                                                        backgroundColor:
                                                          m.color,
                                                      }
                                                    : null
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={m.icon}
                                                ></FontAwesomeIcon>
                                                {m.name}
                                              </CustomBadge>
                                            );
                                          })}
                                        </>
                                      )}
                                  </td>
                                )}
                                {columns.includes("rank") && (
                                  <td>
                                    {m.rank != null &&
                                      getColoredSpan(m.rank.name, m.rank.color)}
                                  </td>
                                )}
                                <td className="text-end">
                                  <ButtonDropdownMenu
                                    buttonClassNames="btn-icon w-30px h-30px ms-auto"
                                    buttonColor="active-light-primary"
                                    buttonContent={
                                      <FontAwesomeIcon
                                        icon="ellipsis-v"
                                        className="me-0"
                                      ></FontAwesomeIcon>
                                    }
                                    menuItems={getContextualMenuItems(m)}
                                  ></ButtonDropdownMenu>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <Paginator
                        forcePage={pagination.page - 1}
                        totalPages={totalPages}
                        handlePageClick={handlePageClick}
                      />
                    </>
                  )}
                  {userChangeRole != null && (
                    <EditMemberProperties
                      container={container}
                      user={userChangeRole}
                      toggleChangeRoleModal={() => {
                        setUserChangeRole(null);
                      }}
                      reload={() => {
                        setUserChangeRole(null);
                        getMembers();
                      }}
                    ></EditMemberProperties>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips
                tipKey={tipsList.KEYS.ENTERPRISE_MEMBERS}
                localeManager={container.localeManager}
              ></Tips>
              {company.external_id != null &&
                es.can(globalContext.member, "company") && (
                  <Card>
                    <CardBody>
                      <Button
                        onClick={() => syncMembers()}
                        color="primary"
                        block
                      >
                        {
                          container.localeManager.strings
                            .syncMembersFromTruckersMP
                        }
                      </Button>
                    </CardBody>
                  </Card>
                )}
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default Members;
